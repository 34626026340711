import React from 'react';
import './PatientCard.css';
import patientAvatar from '../../assets/images/icons/patientavatargrey.png';
import {calculateage} from '../../assets/js/calculate_details'


function PatientCard(props) {
    const patientAge = calculateage(props.patientDetails.patient_dob, props.patientDetails.patient_age);
    return(
        <div className={"patientCardContainer " + ((props.patientDetails.currentApointment.length>0) ? "btnDisabled" : "")}>
            <div className="patientImage">
                <img src={patientAvatar} alt=""/>
            </div>
            <div className="patientInformation">
                <div className="patientName">
                    <span>{props.patientDetails.patient_fullnm} ({(props.patientDetails.sex) ? props.patientDetails.sex: '-'}/{(patientAge)? patientAge + ' y' : '-'})</span>
                </div>
                <div className="patientNumber">
                {(props.patientDetails.patient_phone_number) ? props.patientDetails.patient_phone_number : '--'}
                </div>
            </div>
        </div>
    )
}

export default PatientCard