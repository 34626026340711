import React, { Component } from "react";
import PaymentCard from "../../components/Payment/Payment";
import style from "./EditPayment.module.css";
import { localtimeiso } from "../../assets/js/date_conversions";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "../../axios-order";
import moment from "moment";

class EditPayment extends Component {
  state = {
    billing: [],
    payment: [],
    modeActive: "cash",
    selectedServices: this.props.payment.payment.billing.map((item) => ({
      count: item.units,
      createdtm: item.createdtm,
      updatedtm: item.updatedtm,
      tax: item.tax + "%",
      price: item.price,
      service_type: item.service_type,
      service_id: item.service_id,
      service_info: {
        service_id: item.service_id,
        service_name: item.service_name,
      },
    })),
  };

  handleTotalPaid() {
    let total = 0;
    this.state.billing.forEach((item) => {
      const p = item.price * item.units;
      total += p;
    });
    return total;
  }

  handleTotalFees() {
    let total = 0;
    this.state.payment.forEach((item) => {
      total += item.amount;
    });
    return total;
  }

  editPaymentHandler(modeActive) {
    modeActive = modeActive ? modeActive : this.state.modeActive;
    console.log("thisProps", this.props.payment.payment);
    const pay = this.props.payment.payment;
    let payment = pay.payment;
    let billing = pay.billing;

    const finalBilling = [];
    billing.map((billingService) => {
      const serviceIndex = this.state.selectedServices.findIndex(
        (selectService) =>
          selectService.service_info.service_id === billingService.service_id
      );
      if (serviceIndex === -1) {
        billingService.price = -1;
        finalBilling.push(billingService);
      }
    });

    this.state.selectedServices.map((service) => {
      const finalService = {
        created_by: "reception",
        service_name: service.service_info.service_name,
        updated_by: "reception",
        units: service.count,
        updatedtm: localtimeiso(new Date()),
        service_id: service.service_info.service_id,
        createdtm: service.createdtm,
        tax: service.tax.replace("%", ""),
        service_type: service.service_type,
        price: service.price,
      };
      finalBilling.push(finalService);
    });

    console.log("finalBilling ", finalBilling);
    let payableAmount = 0;
    finalBilling
      .filter((item) => item.price !== -1)
      .forEach((item) => {
        const p = item.price * item.units;
        payableAmount += p;
      });
    console.log("payableAmount ", payableAmount);

    let alreadyPaidAmount = 0;
    payment.forEach((item) => {
      alreadyPaidAmount += item.amount;
    });

    const differAmount = payableAmount - alreadyPaidAmount;
    console.log("differAmount ", differAmount);
    if (differAmount !== 0) {
      const newPaymentObject = {
        amount: differAmount,
        payment_mode: "cash",
        is_service: true,
        updatedtm: localtimeiso(new Date()),
        collected_by: "reception",
        is_refund: "false",
        collectdtm: localtimeiso(new Date()),
      };
      payment.push(newPaymentObject);
      console.log("paymentAmount ", payment);
    }

    let fees_paid = 0;
    payment.forEach((item) => {
      fees_paid += item.amount;
    });

    const reqData = {
      appointment_id: this.props.payment.payment.apptId,
      appt_doctor_id: this.props.payment.payment.docId,
      appt_patient_id: this.props.payment.payment.patId,
      payment: payment,
      billing: finalBilling,
      fees_paid: fees_paid, //this.handleTotalFees(),
      total_amount: payableAmount,
      updatedtm: new moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    console.log("reqData", reqData);
    axios.post("/postnewappointment_reception_web", reqData).then(
      (response) => {
        alert("Payment Updated Successfully");
        this.props.history.push("/reception/dashboard/dayview");
      },
      (error) => {
        //Error Instructions goes here
        alert("Unable to update payment. Kindly reload the page.");
      }
    );
  }

  changePaymentMode(paymentMode) {
    console.log(paymentMode);
    this.setState({ modeActive: paymentMode });
  }
  updateBilling = (childData) => {
    console.log("Check DataBilling", childData);
    this.setState({ billing: childData });
  };

  updatePayment = (child) => {
    this.setState({ payment: child });
  };

  callBackSelectService = (child) => {
    this.setState({ selectedServices: child });
    console.log("Rest", child);
  };

  handltemplateView = (object) => {
    console.log("object", object);
  };

  render() {
    return (
      <div className={style.EditPayment}>
        <PaymentCard
          tempView={this.state}
          handleTempView={this.handltemplateView}
          doctorId={this.props.payment.payment.docId}
          handleCallSelectedService={this.callBackSelectService}
          handleDltServices={this.callDltServices}
          handleCallBilling={this.updateBilling}
          handleCallPayment={this.updatePayment}
          billingObjects={this.state.billing}
          paymentObjects={this.props.payment.payment.payment}
          updateSelectedService={this.state.selectedServices}
        />

        <div className={style.editPaymentBtnGroup}>
          <Button
            className={style.editPaymentBtnEdit}
            onClick={() => this.editPaymentHandler("cash")}
          >
            Done
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.payment,
    routes: state.routes,
    appointments: state.appointment,
  };
};

export default connect(mapStateToProps, null)(EditPayment);
