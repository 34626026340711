import React from 'react';
import './DoctorProfile.css';
import nextIcon from '../../assets/images/icons/next.png';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function DoctorProfile(props) {
    return (        
        <AccordionItem uuid={props.id.toString()}>
            <AccordionItemHeading>
                <AccordionItemButton>
                <div className="sidebar-dropdown" style={{borderLeft:"8px solid " + props.colorCode}} onClick = {() => props.selectCategory(0)}>
                    <div className = "doc-profile">
                        <div className="profile-image" style={{backgroundColor:props.colorCode}}>
                            {(props.docImage && props.docImage!=='NA') ? <img src={props.docImage} alt="" /> : <span className="docLetter">{props.docName.substr(0,1)}</span>}
                        </div>
                        <div className="profile-content">
                            <h3>Dr. {props.docName}</h3>
                            <p>{props.docSpecialization}</p>
                        </div>
                    </div>
                
                    <span className="notification-icon">{props.totalAppts}</span>
                
                </div>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <div className={(props.selectedCategory===0) ? 'dropdownMenu activeItem' : 'dropdownMenu'} onClick = {() => props.selectCategory(0)}>
                    <div className="text">
                        Appointments
                    </div>
                    <div className="counts">
                        {props.upcommingCount}
                    </div>
                    <div><img src = {nextIcon} style={{"opacity" : (props.selectedCategory===0) ? "1":"0"}} alt=""/></div>
                </div>
                
                <div className={(props.selectedCategory===1) ? 'dropdownMenu activeItem' : 'dropdownMenu'} onClick = {() => props.selectCategory(1)}>
                    <div className="text">
                        Patient Seen
                    </div>
                    <div className="counts">
                        {props.patSeen}
                    </div>
                    <div><img src = {nextIcon} style={{"opacity" : (props.selectedCategory===1) ? "1":"0"}}/></div>

                </div>
                <div className={(props.selectedCategory===2) ? 'dropdownMenu activeItem' : 'dropdownMenu'} onClick = {() => props.selectCategory(2)}>
                    <div className="text">
                        Cancelled
                    </div>
                    <div className="counts red">
                    {props.cancelledAppt}
                    </div>
                    <div><img src = {nextIcon} style={{"opacity" : (props.selectedCategory===2) ? "1":"0"}}/></div>
                </div>    
                {/* <div className="dropdownMenu" onClick = {() => props.selectCategory(3)}>
                    <div className="text">
                        Settings
                    </div>
                    <div className="counts">
                        
                    </div>
                </div>         */}
            </AccordionItemPanel>
        </AccordionItem>
            
        
      
   
    );
  }


  export default DoctorProfile;