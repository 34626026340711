import React from "react";
import "./Navbar.css";
import { Dropdown } from "react-bootstrap";

import logo from "../../assets/images/logo.svg";
// import bell from '../../assets/images/icons/bell.svg';
// import settings from '../../assets/images/icons/settings.svg';
import docimage from "../../assets/images/receptionimg.png";

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <div className="dropMenuHeader">
        <div className="item1-drop" onClick={this.handleClick}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

class CustomMenu extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);

    this.state = { value: "" };
  }

  handleChange(e) {
    this.setState({ value: e.target.value.toLowerCase().trim() });
  }

  changeHandler(event) {
    // console.log(event);
  }

  render() {
    const {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
    } = this.props;
    // console.log(this.props.type);
    const { value } = this.state;

    return (
      <div style={style} className={className} aria-labelledby={labeledBy}>
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
}

class Navbar extends React.Component {
  logout() {
    localStorage.removeItem("loginId");
    localStorage.removeItem("addId");
    localStorage.removeItem("print_prescription");
    localStorage.removeItem("is_master");
    localStorage.removeItem("scan_visibility");
    localStorage.removeItem("payment_visibility");
    window.location.href = "/";
  }
  render() {
    // console.log('Props for JNavbar', this.props);
    return (
      <section className="header">
        <div className="headerContainer">
          <div className="logo container-items">
            <img src={logo} alt="" />
          </div>
          {/* <input className="container-items searchInput" type="text" name="" id="" placeholder="Search Patients " /> */}
          {/* <a href="" className="bell-icon container-items">
                    <img src={bell} alt="" />
                    <span className="notification-count">5</span>
                </a>
                <a href="" className="settings-icon container-items">
                    <img src={settings} alt="" />
                </a>  */}
          <div className="nav-dashboard-item">
            <div className="castIcon">
              <google-cast-launcher id="castbutton"></google-cast-launcher>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <div className="logout-image container-items">
                  <img src={docimage} alt="" />
                </div>
                <div className="logout-content container-items">
                  <div className="user-name">
                    {/*this.props.username*/}
                    {localStorage.getItem("userName")}
                  </div>
                  <div className="specialization">
                    {/*this.props.specialization*/}{" "}
                    {localStorage.getItem("userSpecialization")}
                    <div className="downIcon"></div>
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item onClick={() => this.logout()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </section>
    );
  }
}
export default Navbar;
