import React from "react";
import "./PrescriptionModal.css";
import { connect } from "react-redux";
import prescImage from "../../assets/images/talwar_clinic.png";

class PrescriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printingDetails: {},
      apptDate: "",
    };
  }

  componentDidMount() {
    if (this.props.patientDetails.printingDetails) {
      // debugger;
      console.log(
        "Patient Details to print ",
        this.props.patientDetails.printingDetails
      );
      console.log("Coordinates ", this.props.patientDetails.printCoordinates);
      console.log("Appointment Date ", this.props.patientDetails.apptDate);
      let fulldate = this.getFullDate(this.props.date.date);
      console.log(fulldate);
      let patientDetails = this.props.patientDetails.printingDetails;
      patientDetails.printCoordinates =
        this.props.patientDetails.printCoordinates;
      this.setState({
        printingDetails: patientDetails,
        apptDate: fulldate,
      });
    } else {
      window.location.href = "/reception/dashboard/dayview";
    }
  }

  componentDidUpdate() {
    window.print();
    window.location.href = "/reception/dashboard/dayview";
    // this.props.history.push('/reception/dashboard/dayview');
  }

  getFullDate(inputDate) {
    let date = inputDate.getDate();
    let month = inputDate.getMonth() + 1;
    let year = inputDate.getFullYear();
    let fulldate = `${date}/${month}/${year}`;
    return fulldate;
  }

  render() {
    let printingDetails = this.state.printingDetails;
    let date = this.state.apptDate;
    console.log(printingDetails, date);
    let printingCoordinates = {};
    let nameTop = 0;
    let nameLeft = 0;
    let dateTop = 0;
    let dateLeft = 0;
    let slotNoTop = 0;
    let slotNoLeft = 0;
    let cityTop = 0;
    let cityLeft = 0;
    let doctorOrder = null;
    let tickCoordinatesTop = 0;
    let tickCoordinatesLeft = 0;
    let appointmentTypeTop = 0;
    let appointmentTypeLeft = 0;

    if (printingDetails) {
      // printingCoordinates = printingDetails.printCoordinates;
      console.log(this.props.patientDetails);
      printingCoordinates = this.props.patientDetails.printCoordinates;
      console.log(printingCoordinates);
      if (
        printingCoordinates !== undefined &&
        Object.keys(printingCoordinates).length > 0
      ) {
        nameTop = printingCoordinates.p_name
          ? printingCoordinates.p_name[0]
          : null;
        nameLeft = printingCoordinates.p_name
          ? printingCoordinates.p_name[1]
          : null;
        dateTop = printingCoordinates.appt_date
          ? printingCoordinates.appt_date[0]
          : null;
        dateLeft = printingCoordinates.appt_date
          ? printingCoordinates.appt_date[1]
          : null;
        slotNoTop = printingCoordinates.slot_number
          ? printingCoordinates.slot_number[0]
          : null;
        slotNoLeft = printingCoordinates.slot_number
          ? printingCoordinates.slot_number[1]
          : null;
        cityTop = printingCoordinates.city_coordinates
          ? printingCoordinates.city_coordinates[0]
          : null;
        cityLeft = printingCoordinates.city_coordinates
          ? printingCoordinates.city_coordinates[1]
          : null;
        tickCoordinatesTop = printingCoordinates.tick_coordinates
          ? printingCoordinates.tick_coordinates[0]
          : null;
        tickCoordinatesLeft = printingCoordinates.tick_coordinates
          ? printingCoordinates.tick_coordinates[1]
          : null;
        appointmentTypeTop = printingCoordinates.appointment_type
          ? printingCoordinates.appointment_type[0]
          : null;
        appointmentTypeLeft = printingCoordinates.appointment_type
          ? printingCoordinates.appointment_type[1]
          : null;
        doctorOrder = printingCoordinates.doctor_order
          ? printingCoordinates.doctor_order
          : null;
      } else {
        window.location.href = "/reception/dashboard/dayview";
      }
    }
    console.log(printingCoordinates);
    console.log(prescImage);
    // let nameTop = printingCoordinates.name.top;
    // let nameLeft = printingCoordinates.name.left;
    return (
      <div>
        <div className="mainDiv">
          <div className="imageContainer" id="prescImage">
            {/* {(doctorOrder===1)?<div className="checkMark" style={{top: tickCoordinatesTop+'px', left: tickCoordinatesLeft+'px'}}></div>:null}
                        {(doctorOrder===2)?<div className="checkMark" style={{top: tickCoordinatesTop+'px', right: tickCoordinatesLeft+'px'}}></div>:null}
                        {(doctorOrder===3)?<div className="checkMark" style={{top: tickCoordinatesTop+'px', left: tickCoordinatesLeft+'px'}}></div>:null} */}
            {/* <img src={prescImage} alt="" /> */}
            <div
              className="cityName"
              style={{
                top: parseInt(cityTop) + "px",
                left: parseInt(cityLeft) + "px",
              }}
              id="upperLayer"
            >
              {printingDetails ? printingDetails.city : ""}
            </div>
            {nameTop && nameLeft ? (
              <div
                className="upperLayer"
                style={{ top: parseInt(nameTop) + "px", left: nameLeft + "px" }}
                id="upperLayer"
              >
                {printingDetails ? printingDetails.patientName : ""}
              </div>
            ) : null}
            {dateTop && dateLeft ? (
              <div
                className="dateContent"
                style={{ top: parseInt(dateTop) + "px", left: dateLeft + "px" }}
              >
                {printingDetails ? date : ""}
              </div>
            ) : null}
            {slotNoTop && slotNoLeft ? (
              <div
                className="slotNo"
                style={{
                  top: parseInt(slotNoTop) + "px",
                  left: parseInt(slotNoLeft) + "px",
                }}
              >
                {printingDetails ? printingDetails.slotNumber : ""}
              </div>
            ) : null}
            {appointmentTypeTop && appointmentTypeLeft ? (
              <div
                className="urgentAppt"
                style={{
                  top: parseInt(appointmentTypeTop) + "px",
                  left: parseInt(appointmentTypeLeft) + "px",
                }}
              >
                {printingDetails && printingDetails.appointmentType
                  ? printingDetails.appointmentType === "Normal"
                    ? ""
                    : "Urgent"
                  : ""}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  patientDetails: state.printDetails,
  date: state.date,
});

export default connect(mapStateToProps, null)(PrescriptionModal);
