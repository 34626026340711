import React, { Component } from "react";
import MonthCalender from "../../components/MonthCalender/MonthCalender";
import CalenderHead from "../../components/CalenderHead/CalenderHead";
import "./Dashboard.css";
import DoctorProfile from "../../components/DoctorProfile/DoctorProfile";
import { connect } from "react-redux";
import DayView from "../../components/DayView/DayView";
import { Accordion } from "react-accessible-accordion";
import AllDoctorProfile from "../../components/AllDoctorProfile/AllDoctorProfile";
import Calendar from "react-calendar";
import AllDocIcon from "../../assets/images/alldoc.png";
import AddApptIcon from "../../assets/images/icons/addappt.png";
import axios from "../../axios-order";
import { Link } from "react-router-dom";
import moment from "moment";
import { sortAppointmentsByHour } from "../../assets/filters/data_filter";
import { sortByDoctorName } from "../../assets/js/sorting_functions";
import { ToastContainer } from "react-toastify";
import { localtimeiso } from "../../assets/js/date_conversions";
import { imageFORMULAScanJS } from "../../assets/js/scan";
import { Modal, Button } from "react-bootstrap";
import Spinner from "../../assets/images/spinner.gif";
import cardIcon from "../../assets/images/icons/card.svg";
import cashIcon from "../../assets/images/icons/cash_icon.svg";
import walletIcon from "../../assets/images/icons/wallet.svg";
import closeIcon from "../../assets/images/icons/close-button.svg";

function PaymentSummary({ totalPayment, isModalActive, hideSummary }) {
  console.log("total payment", totalPayment);
  return (
    <Modal
      show={isModalActive}
      onHide={() => hideSummary()}
      backdrop={"static"}
      animation={true}
      keyboard={false}
      centered
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="previewModalBody">
        <div className="closeButton" onClick={() => hideSummary()}>
          <img src={closeIcon} alt="close" />
        </div>
        <div class="totalPaymentModal">
          <div class="paymentModalBody">
            <div class="totalCollectionLabel">Total Collection</div>
            <div class="totalCollectionValue">&#8377; {totalPayment.total}</div>
            <div className="receivedBy">
              <div>
                <p>By Reception</p>
                <p className="receivedByAmt">
                  {totalPayment.rec_amount < 0
                    ? totalPayment.rec_amount + "(Advance)"
                    : totalPayment.rec_amount}
                </p>
              </div>
              <div>
                <p>By Doctor</p>
                <p className="receivedByAmt">
                  {totalPayment.doc_amount < 0
                    ? totalPayment.doc_amount + "(Advance)"
                    : totalPayment.doc_amount}
                </p>
              </div>
              {/* <div>By Doc : {totalPayment.doc_amount}</div> */}
            </div>
          </div>
          <div class="paymentModalFooter">
            <div class="paymentByCash">
              <div class="paymentIconValue">
                <span class="cashIcon">
                  <img src={cashIcon} alt="" />
                </span>
                <span class="paymentModeValue">
                  &#8377; {totalPayment.cash}
                </span>
              </div>
              <div class="paymentModeLabel">Cash</div>
            </div>
            <div class="paymentByCard">
              <div class="paymentIconValue">
                <span class="cardIcon">
                  <img src={cardIcon} alt="" />
                </span>
                <span class="paymentModeValue">
                  &#8377; {totalPayment.card}
                </span>
              </div>
              <div class="paymentModeLabel">Card</div>
            </div>
            <div class="paymentByEWallet">
              <div class="paymentIconValue">
                <span class={walletIcon}>
                  <img src={walletIcon} alt="" />
                </span>
                <span class="paymentModeValue">
                  &#8377; {totalPayment.ewallet}
                </span>
              </div>
              <div class="paymentModeLabel">eWallet</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ScanPreview({
  patientName,
  scanState,
  imageUrl,
  isModalActive,
  discardUpload,
  submitAppt,
  addMore,
  isActive,
  showImage,
  deletePreview,
  scanInProgress,
  selectedCategory,
  apptInProgress,
}) {
  console.log("Scan In Progress", scanInProgress);
  console.log("APPT IN PROGRESS", apptInProgress);
  const imageTag = imageUrl.map((img, index) => {
    return (
      <img
        key={"img" + index}
        src={img}
        alt="scannedImage"
        style={{
          width: "100%",
          display: "inline",
          display: isActive === index ? "block" : "none",
        }}
      />
    );
  });

  const imageThumbnail = imageUrl.map((img, index) => {
    return img !== "NA" ? (
      <img
        key={"img" + index}
        src={img}
        alt="scannedImage"
        style={{
          width: "100%",
          border: isActive === index ? "1px solid red" : "none",
        }}
        onClick={() => showImage(index)}
      />
    ) : null;
  });

  return (
    <Modal
      show={isModalActive}
      onHide={(type) => discardUpload(type)}
      backdrop={"static"}
      animation={true}
      keyboard={false}
    >
      <Modal.Body className="previewModalBody">
        {imageUrl.findIndex((item) => item !== "NA") > -1 ||
        selectedCategory === 1 ? (
          <>
            <div className="scanPreviewHead">
              <div className="scanForHeading">
                <div className="scanFor">Prescriptions For</div>
                <div className="scanPatientName">{patientName}</div>
              </div>
              {scanInProgress ? (
                <div className="rescanInProgress">
                  {scanState}
                  <img src={Spinner} />
                </div>
              ) : (
                <div></div>
              )}
              <div>
                <Button
                  variant="secondary"
                  onClick={() => discardUpload()}
                  disabled={apptInProgress}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => submitAppt()}
                  disabled={
                    apptInProgress ||
                    imageUrl.findIndex((item) => item !== "NA") === -1
                  }
                >
                  {selectedCategory === 1 ? "Update" : "Submit"}
                </Button>
              </div>
              {/* <Button onClick={()=>addMore()}>Add More</Button> */}
            </div>
            <div className="previewContent">
              {imageUrl.findIndex((item) => item !== "NA") !== -1 ? (
                <div className="previewPrescriptionSection">
                  <div
                    class="deleteScan"
                    onClick={() => deletePreview(isActive)}
                    style={{ opacity: apptInProgress ? "0.6" : "1" }}
                  >
                    Delete
                  </div>
                  {imageTag}
                </div>
              ) : (
                <div className="noPrescription">No Prescription</div>
              )}
              <div className="previewThumbnailSection">
                <Button
                  variant="danger"
                  onClick={() => addMore()}
                  disabled={
                    apptInProgress ||
                    imageUrl.filter((item) => item != "NA").length === 5
                  }
                >
                  Add More
                </Button>
                <div className="previewScanThumbnail">{imageThumbnail}</div>
              </div>
              {/* <button onClick={()=>showImage((isActive===0)? 0 : isActive-1)}>Previous</button>
                        <button onClick={()=>showImage((isActive===imageUrl.length-1)? imageUrl.length-1 : isActive+1)}>Next</button> */}
            </div>
          </>
        ) : (
          <div className="scannigSpinner">
            <img src={Spinner} alt="scanning..." />
            {scanState}
            {/* <div className="stopScanBtn"><Button onClick={()=>discardUpload('kill')} disabled={scanState==="Scan In Progress..."}>Stop Scan</Button></div> */}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

function LeftBlock({
  changedate,
  date,
  allDocProfileView,
  doctorList,
  expandedView,
  selectedCategory,
}) {
  return (
    <div>
      <Calendar
        value={date}
        onChange={(date) => changedate(date)}
        minDetail="month"
        minDate={selectedCategory == 0 ? new Date() : null}
        maxDate={selectedCategory == 1 ? new Date() : null}
      />
      <div className="sidebarContainer">
        <div className="sidebar">
          <Accordion preExpanded={[expandedView]}>
            {allDocProfileView}
            {doctorList}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

function RightBlock({
  categoryName,
  view,
  date,
  selectedCategory,
  selectedDoctor,
  docColorCodes,
  isDayDataLoading,
  totalPages,
  selectedPage,
  apptDocId,
  apptAddId,
  appointments,
  changedate,
  loadMore,
  cancelAppt,
  rescheduleAppt,
  subTotal,
  showPayment,
  setQuickAdd,
  handleAddAppointment,
}) {
  return (
    <div>
      <CalenderHead
        date={date}
        category={selectedCategory}
        changeDate={(date) => changedate(date)}
      />
      <div className="apptListHeader">
        <div className="apptListLeft">
          <img src={AllDocIcon} alt="doctor_image" />
          <div className="apptListAllDocs">
            <div className="apptFor">{categoryName} for</div>
            <div className="apptAllDoctors">
              {selectedDoctor ? selectedDoctor.doctor_fullnm : "All Doctors"}
            </div>
          </div>
        </div>
        {/* {(localStorage.getItem('payment_visibility')==='true' && selectedCategory===1) ? (<div className="paymentSummary">
                    <div className="ps-text">Total Payment</div>
                    <div className="ps-amt">&#x20b9; {(selectedCategory!==2) ? subTotal : 0}</div>
                </div>) : null} */}
        <div className="headButtonSection">
          {localStorage.getItem("payment_visibility") === "true" &&
          selectedCategory === 1 ? (
            <div className="totalCollectionBtn" onClick={() => showPayment()}>
              <span>&#8377;</span> Total Collection
            </div>
          ) : null}

          {localStorage.getItem("quick_appt") === "true" ? (
            <Button
              variant="default"
              className={
                Date.parse(date) >= new Date().setHours(0, 0, 0, 0)
                  ? "quickApptBtn"
                  : "hide"
              }
              onClick={() => {
                setQuickAdd(true);
              }}
            >
              Quick Appointment
            </Button>
          ) : null}
          {Date.parse(date) >= new Date().setHours(0, 0, 0, 0) ? (
            <div className="apptListRight" onClick={handleAddAppointment}>
              <img src={AddApptIcon} alt="add_appointment" />
              <div className="addApptLabel">Add Appointment</div>
              {/* <Link to="/reception/createappointment/">
                           
                        </Link> */}
            </div>
          ) : null}
        </div>
      </div>
      {docColorCodes ? view : null}
      {/* <Route path="/reception/dashboard/dayview" exact component = {() => <DayView isLoading={this.state.isDayDataLoading} 
            isMoreDataExist={this.state.totalPages>this.state.selectedPage} 
            appointments={this.props.appointments.appointments}
            loadMore = {() => this.getappointments(this.props.user.userData.docId, this.props.user.userData.addId, this.props.date.date, this.state.selectedCategory, true)}
            cancelAppt = {(apptObject)=>this.cancelApptHandler(apptObject)}
            />} />
            <Route path="/reception/dashboard/monthview" exact component = {MonthCalender} /> */}
    </div>
  );
}

class Dashboard extends Component {
  state = {
    selectedDoctor: null,
    individualDoctor: "doctor",
    selectedAddress: null,
    docColorCodes: null,
    colorCodes: [
      "#B2FFDC",
      "#F5C7A6",
      "#B2DDFF",
      "#FFB2B2",
      "#DEEFA0",
      "#F2A4CF",
      "#B2B2B2",
      "#E0B2FF",
      "#FFEEBF",
      "#B2B4FF",
    ],
    activeIndex: "1",
    selectedCategory: 0,
    doctors: null,
    appointments: null,
    selectedPage: 1,
    totalPages: 0,
    isDayDataLoading: false,
    reloadId: null,
    activeDoctor: null,
    activeView: 0,
    viewType: "dayview",
    subTotal: 0,
    isLoadingAppt: false,
    isScannedModalActive: false,
    scanInProgress: false,
    scanState: "",
    // scannedUrl:['https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg', 'https://upload.wikimedia.org/wikipedia/commons/0/03/Mohenjodaro_-_view_of_the_stupa_mound.JPG'],
    scannedUrl: ["NA", "NA", "NA", "NA", "NA"],
    selectedAppt: null,
    fileName: ["NA", "NA", "NA", "NA", "NA"],
    totalPresCount: 0,
    isActivePreview: 0,
    submitApptInProgress: false,
    numberOfScannedFiles: 0,
    fqdn: "localhost:6851",
    isabandoned: false,
    prescriptionPritingDoctors: [],
    docPrintList: null,
    isPaymentSummaryActive: false,
    paymentByModes: null,
  };

  //Near to depreceation (componentWillMount)
  componentWillMount() {
    //Stored Username and Address Id...
    const loggedUserId = localStorage.getItem("loginId");
    const loggedUserAdd = localStorage.getItem("addId");
    this.setState(
      {
        selectedDoctor: loggedUserId,
        selectedAddress: loggedUserAdd,
        activeDoctor: loggedUserId,
        isLoadingAppt: true,
      },
      () => {
        console.log(this.state.isLoadingAppt);
      }
    );

    //If user exist get doctor details else Redirect to login page...
    //Defalt date returned by props.date is current date...
    if (loggedUserId && loggedUserAdd) {
      this.getdoctordetails(loggedUserId, loggedUserAdd, new Date());
    } else {
      this.props.history.push("/login");
    }
    //Set current date using reducer...
    //this.props.onDateChange(new Date());
    this.props.setQuickAdd(false);
    // window.scrollTo(0, 0);
    this.props.storSelectedDoc(null); //Only for multi clinics (To be handled for single doctor)
    this.props.rescheduleAppt(null);
  }

  componentWillUnmount() {
    clearInterval(this.state.reloadId);
  }

  postprescriptions(appointment) {
    let fileCount = 0;
    var obj = {
      img_patient_id: appointment.patient_id,
      img_doctor_id: appointment.appt_doctor_id,
      image_name: appointment.patient_fullnm,
      phone_num: appointment.patient_phone_number,
      createdtm: appointment.updatedtm,
      is_public: true,
      img_appt_id: appointment.appointment_id,
      final_image_data: this.state.scannedUrl,
    };
    console.log("On submit scanned appt prescription", obj);
    axios.post("postimage_v3/", obj).then(
      (response) => {
        this.setState({
          isScannedModalActive: false,
          submitApptInProgress: false,
        });
        this.getdoctordetails(
          this.state.selectedDoctor,
          this.state.selectedAddress,
          this.props.date.date
        );
      },
      (error) => {
        alert("Unable to save prescription. Please try again.");
        this.setState({ submitApptInProgress: false });
      }
    );
  }

  submitApptHandler() {
    this.setState({ submitApptInProgress: true });
    const appointment = this.state.selectedAppt;

    const postObject = {
      appointment_id: appointment.appointment_id,
      appt_patient_id: appointment.patient_id,
      appt_doctor_id: appointment.appt_doctor_id,
      appt_address_id: appointment.appt_address_id,
      status: "Diagnosed",
      updated_by: "website",
      updatedtm: new moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    axios.post("/postnewappointment_reception_web", postObject).then(
      (response, apptObject) => {
        this.postprescriptions(appointment);
      },
      (error) => {
        //Error Instructions goes here
        alert("Unable to update appointment.Kindly reload the page.");
        this.setState({ submitApptInProgress: false });
      }
    );
  }

  scanHandler(appointment) {
    const selDocScanPath = this.props.doctorsData.user.userData.doctors.filter(
      (doc) => doc.doctor_id === appointment.appt_doctor_id
    )[0].cloud_path;
    console.log("selDocScanPath", selDocScanPath);
    if (
      this.state.scannedUrl.indexOf("NA") === -1 &&
      this.state.scannedUrl.length === 5
    ) {
      alert("Only 5 files can be uploaded at once.");
      return null;
    }
    const ref = this;
    var fqdn = this.state.fqdn; //Default port for scanner
    console.log("FQDN", fqdn);
    ref.setState({
      isabandoned: false,
      scanInProgress: true,
      isScannedModalActive: true,
      selectedAppt: appointment,
      scanState: "Preparing to Scan",
    });

    //Start scanning process
    // const a = Date.parse(new Date());
    imageFORMULAScanJS.getScannerList(fqdn).then(
      function (success) {
        // const b = Date.parse(new Date()) - a;
        console.log("Get Scanner List", success);
        let scanner_id = null;
        if (success.value.scanners.length === 0) {
          alert("No scanner connected.");
          return null;
        }
        // debugger;
        const c = Date.parse(new Date());
        scanner_id = success.value.scanners[0].scanner_id;
        imageFORMULAScanJS.connectScanner(fqdn, scanner_id, 1).then(
          (response) => {
            const d = Date.parse(new Date()) - c;
            console.log("Connect Scanner", d);
            const connection_id = response.value.connection_id;
            ref.setState({ connectionId: connection_id });
            imageFORMULAScanJS
              .getScanState(fqdn, scanner_id, connection_id)
              .then(function (response) {
                const settings = {
                  colormode: 4,
                  scanside: 0,
                  resolution: 150,
                  fileformat: 3,
                  double_feed_detection: 0,
                  document_orientation: 0,
                  blank_page_detection_threshold: 0,
                  schmprefix: "",
                  schmseparator: 0,
                  schmdatetime: -2,
                  show_error_message: 1,
                  destination:
                    "https://api.athancare.com/upload_scanned_image/",
                  // "destination":"http://182.77.63.129:4004/upload_scanned_image/",
                  // "destination":"http://182.77.63.129:4013/upload_scanned_image/",
                  destinationheaders: {},
                  destinationparameters: [],
                  destinationfileparameter: {
                    // "contenttype" : "image/jpeg",
                    contenttype: "application/octet-stream",
                    //"contentdisposition": "form-data; name='myscanfile'; filename='SCANNED_FILENAME'"
                    contentdisposition:
                      "form-data; name='file'; filename='SCANNED_FILENAME'",
                  },
                  pdf_pages_per_file: 0,
                  pdf_ocr: 1,
                  pdf_ocr_language: "engjpn",
                };

                const e = Date.parse(new Date());
                imageFORMULAScanJS
                  .updateSettings(fqdn, scanner_id, connection_id, settings)
                  .then(function (response) {
                    const f = Date.parse(new Date()) - e;
                    console.log("update settings".f);
                    const g = Date.parse(new Date());
                    imageFORMULAScanJS
                      .startScan(fqdn, scanner_id, connection_id)
                      .then(
                        function (response) {
                          const h = Date.parse(new Date()) - g;
                          console.log("Start Scan", h);
                          console.log(response);
                          const scan_id = response.value.scan_id;
                          ref.setState({ scanState: "Scan In Progress..." });
                          const i = Date.parse(new Date());
                          imageFORMULAScanJS
                            .monitorScanState(
                              fqdn,
                              scanner_id,
                              connection_id,
                              50000
                            )
                            .then(
                              function (response) {
                                const j = Date.parse(new Date()) - i;
                                console.log("Monitor state", j);
                                const k = Date.parse(new Date());
                                ref.setState({
                                  scanState: "Generating Preview",
                                });
                                imageFORMULAScanJS
                                  .getFileList(
                                    fqdn,
                                    scanner_id,
                                    connection_id,
                                    scan_id
                                  )
                                  .then(function (response) {
                                    console.log(response);
                                    // debugger;
                                    const l = Date.parse(new Date()) - k;
                                    console.log("get File list", l);
                                    console.log(
                                      "SCAN IN PROGRESS",
                                      ref.state.isScannedModalActive
                                    );
                                    let fileUrl = ref.state.scannedUrl;
                                    let fileName = ref.state.fileName;
                                    //Get index of first NA
                                    const fileIndex = fileUrl.indexOf("NA");
                                    fileUrl[fileIndex] =
                                      selDocScanPath +
                                      response.value.files[0].filename;
                                    fileName[fileIndex] =
                                      response.value.files[0].filename;
                                    console.log("FILE URLS LIST", fileUrl);
                                    console.log("FILE NAME LIST", fileName);
                                    ref.setState({
                                      numberOfScannedFiles:
                                        ref.state.numberOfScannedFiles + 1,
                                      scanInProgress: false,
                                      scannedUrl: fileUrl,
                                      fileName: fileName,
                                      totalPresCount:
                                        ref.state.totalPresCount + 1,
                                      isActivePreview: fileIndex,
                                      scanState: "",
                                    });
                                  });
                              },
                              function (error) {
                                if (ref.state.numberOfScannedFiles === 0) {
                                  ref.setState({
                                    scanInProgress: false,
                                    isScannedModalActive: false,
                                  });
                                }
                                ref.setState({ scanInProgress: false });
                              }
                            );
                        },
                        function (error) {
                          ref.setState({ scanInProgress: false });
                        },
                        function (error) {
                          ref.setState({ scanInProgress: false });
                        }
                      );
                  });
              });
          },
          function onerror(error) {
            console.log("Here in 1");
            alert("Scanner Not Connected");
            ref.setState({
              scanInProgress: false,
              isScannedModalActive: false,
              scanState: "",
            });
          }
        );
      },
      function onerror(error) {
        console.log("Here in 2");
        console.log(error);
        // debugger;
        alert("Scanner Not Connected");
        ref.setState({
          scanInProgress: false,
          isScannedModalActive: false,
          scanState: "",
        });
      }
    );
  }

  deletePreviewHandler(index) {
    if (this.state.scanInProgress || this.state.submitApptInProgress) {
      return null;
    }
    // debugger;
    const updateUrls = this.state.scannedUrl;
    if (
      updateUrls.filter((item) => item === "NA").length === 4 &&
      this.state.selectedCategory === 0
    ) {
      const isConfirm = window.confirm(
        "Do you want to delete all scanned prescriptions ? "
      );
      if (isConfirm) {
        this.setState({
          isScannedModalActive: false,
          scannedUrl: ["NA", "NA", "NA", "NA", "NA"],
          fileName: ["NA", "NA", "NA", "NA", "NA"],
          scanState: "",
        });
      } else {
        return null;
      }
    }
    updateUrls[index] = "NA";
    const updatedFileNames = this.state.fileName;
    updatedFileNames[index] = "NA";
    let activeIndex = this.state.isActivePreview;
    for (let i = 0; i < 5; i++) {
      if (updateUrls[i] != "NA") activeIndex = i;
    }
    // console.log(activeIndex);
    this.setState({
      scannedUrls: updateUrls,
      fileName: updatedFileNames,
      isActivePreview: activeIndex,
      noPrescription:
        updateUrls.findIndex((item) => item !== "NA") === -1 ? true : false,
    });
  }

  getcountforcategory(category, upcommingAppts, seenAppts, noShowAppts) {
    switch (category) {
      case 0: {
        return upcommingAppts;
        break;
      }
      case 1: {
        return seenAppts;
        break;
      }
      case 2: {
        return noShowAppts;
        break;
      }
      default: {
        return 0;
      }
    }
  }

  rescheduleAppointmentHandler(apptObject, apptType) {
    // debugger
    console.log(apptObject, apptType);
    this.props.onDateChange(new Date());
    this.props.rescheduleAppt(apptObject, apptType);
    this.props.history.push("/reception/createappointment");
  }

  editAppointmentHandler(apptObject) {
    console.log(apptObject);
    this.props.editAppointment(apptObject);
  }

  checkappointmemtswillload(isMaster, userData) {
    return true;
  }

  datebycategory(date, category) {
    let resultDate = date;
    if (
      category == 0 &&
      date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    ) {
      resultDate = new Date();
    }
    if (
      category == 1 &&
      date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    ) {
      resultDate = new Date();
    }
    return resultDate;
  }

  getdoctordetails(userId, addId, date) {
    this.props.onDateChange(date);
    axios
      .post("/get_doctorDetails_And_CountAppointment_web", {
        id: userId,
        for_date: new moment(date).format("YYYY-MM-DD"),
        address_id: addId,
      })
      .then(
        (response) => {
          console.log(response.data);
          // const prescriptionPritingDoctors = this.getDoctorPrescriptionCoordinates(response.data.doctors);
          // // console.log(prescriptionPritingDoctors);
          // // console.log(prescriptionPritingDoctors[0].prescription_coordinates);
          // const presciptionCoordinates = prescriptionPritingDoctors[0].prescription_coordinates;
          // // console.log(presciptionCoordinates);
          // this.setState({
          //     presciptionCoordinates: presciptionCoordinates,
          //     prescriptionPritingDoctors:prescriptionPritingDoctors
          // })
          // debugger;
          //Initialize user object which is stored in a root state by passing it to reducer.
          const userData = {
            isHospital: response.data.reception_app_web_credential
              ? response.data.reception_app_web_credential
              : false,
            docName: response.data.doctor_fullnm,
            doctor_id: response.data.doctor_id,
            docImage: response.data.doctor_image,
            specialization: response.data.doctor_speciality,
            address_id: addId,
          };

          //if User is multi clinic , set additional fields
          if (response.data.reception_app_web_credential) {
            userData.upcommingCount = response.data.upcoming_appointment_count;
            userData.patSeenCount = response.data.seen_patient;
            userData.cancelledCount = response.data.cancel_count;
            userData.totalApptCount = response.data.total_Appointment;
            this.setState({ activeIndex: "0" });
          }

          //Sort doctor list by name if it is multi clinic
          userData.doctors = response.data.reception_app_web_credential
            ? sortByDoctorName(response.data.doctors)
            : [response.data];
          const docPrintList = userData.doctors.filter((doc) => {
            return doc.print_prescription;
          });

          if (docPrintList.length > 0 || 1) {
            console.log(docPrintList);
            this.props.setDocPrintList(docPrintList);
          }

          //For single doctor
          if (userData.doctors.length == 1) {
            this.setState({
              individualDoctor: "doctor",
              docPrintList: docPrintList,
            });
            this.props.storSelectedDoc(userData.doctors[0]);
          } else {
            this.setState({
              individualDoctor: "multiclinic",
              docPrintList: docPrintList,
            });
          }

          //Set color codes object
          const docColorCodes = {};
          if (userData.doctors.length > this.state.colorCodes) {
            const colorCodes = [...this.state.colorCodes];
            this.setState({
              colorCodes: colorCodes.push(this.state.colorCodes),
            });
          }
          for (let i = 0; i < userData.doctors.length; i++) {
            docColorCodes[userData.doctors[i].doctor_id] =
              this.state.colorCodes[i];
            userData.doctors[i].colorCode = this.state.colorCodes[i];
          }
          this.props.setColorCodes(docColorCodes);

          const isLoadAppts = this.checkappointmemtswillload(
            response.data.reception_app_web_credential,
            response.data
          );

          //If user is hospital (master clinic)
          if (true || isLoadAppts) {
            if (!response.data.reception_app_web_credential) {
              this.getappointments(
                userId,
                addId,
                date,
                this.state.selectedCategory,
                false
              );
              this.setState({ selectedDocName: response.data.doctor_fullnm });
            } else {
              if (!this.state.selectedCategory) {
                this.setState({ selectedDocName: "All Doctors" });
              }
              this.getappointments(
                this.state.activeDoctor,
                addId,
                date,
                this.state.selectedCategory,
                false
              );
            }
          }

          //Set doctors data to reducer
          this.props.userData(userData);

          //If previos timeout request(promise) exist destroy it.
          clearInterval(this.state.reloadId);

          //If date is today , reload data after every 15 secondes
          if (
            this.props.date.date.setHours(0, 0, 0, 0) >=
            new Date().setHours(0, 0, 0, 0)
          ) {
            const intervalId = setTimeout(
              () => this.getdoctordetails(userId, addId, date),
              60000
            );
            this.setState({ reloadId: intervalId });
          }
        },
        (error) => {
          //Reload data for response
          if (error.response === undefined) {
            setTimeout(() => this.getdoctordetails(userId, addId, date), 2000);
          }
          //Error I
        }
      );
  }

  getPrintingPrescriptionDoctors(doctors) {
    //Array of Doctors (doctors)
    let doctor = doctors.filter((doctor) => {
      return doctor.doctor_id === 100576;
    });
    return doctor;
  }

  calculatetotalpages(totalLength, dataPerPage) {
    return totalLength % 10 == 0
      ? Math.floor(totalLength / 10)
      : Math.floor(totalLength / 10) + 1;
  }

  getappointments(docid, addid, date, category, loadMoreFlag) {
    //Set props and states
    this.setState({ selectedCategory: category, isDayDataLoading: true });
    date = new moment(date).format("YYYY-M-DD");
    const selectedPage = loadMoreFlag
      ? parseInt(this.state.selectedPage) + 1
      : 1;
    let url = null;
    switch (category) {
      case 0: {
        url = "/get_upcoming_appointments_web";
        break;
      }
      case 1: {
        url = "/get_diagnosed_Patient_web";
        break;
      }
      case 2: {
        url = "/get_cancelled_appointments_web";
        break;
      }
      default:
        url = null;
    }

    axios
      .post(url, {
        id: docid,
        for_date: date,
        page_num: null,
        address_id: addid,
      })
      .then(
        (response) => {
          console.log("List of appointments", response.data);
          // debugger;
          //const apptData = (loadMoreFlag) ? this.state.appointments.concat(response.data.appointments) :  response.data.appointments;
          const apptData = response.data.appointments;
          //Return Object with appointments arrange by hour with hour as key & appointment as value
          const appointments = sortAppointmentsByHour(apptData, category);
          console.log("Response of appointments", appointments);
          //Set Props and state
          this.props.storeUpcomingAppointments(appointments);

          //Calulate total pages based on 10 appointments per page
          const totalPages = this.calculatetotalpages(
            response.data.totalUpcomingApptCount,
            10
          );

          console.log(response.data);
          const paymentByModes = {
            total: response.data.sub_total_rec + response.data.sub_total_doc,
            cash: response.data.cash,
            card: response.data.card,
            rec_amount: response.data.sub_total_rec,
            doc_amount: response.data.sub_total_doc,
            ewallet: response.data.eWallet,
          };
          console.log(paymentByModes);

          //Set State
          this.setState({
            appointments: response.data.appointments,
            isDayDataLoading: false,
            totalPages: totalPages,
            selectedPage: selectedPage,
            subTotal: response.data.sub_total,
            isLoading: false,
            paymentByModes: paymentByModes,
          });
        },
        (error) => {
          if (error.response === undefined) {
            setTimeout(
              () =>
                this.getappointments(
                  docid,
                  addid,
                  date,
                  category,
                  loadMoreFlag
                ),
              2000
            );
          }
          //Error Instructions goes here
          //alert('Unable to fetch appointments.Kindly reload the page.');
        }
      );
  }

  changeDate(date) {
    this.getdoctordetails(
      localStorage.getItem("loginId"),
      localStorage.getItem("addId"),
      date
    );
  }

  cancelApptHandler(apptId, apptStatus, appointment) {
    const payment = appointment.payment;
    if (payment.length > 0) {
      payment.push({
        amount: -1 * appointment.fees_paid,
        doctor_id: appointment.appt_doctor_id,
        payment_mode: "cash",
        is_service: true,
        updatedtm: localtimeiso(new Date()),
        collected_by: "reception",
        is_refund: "false",
        collectdtm: localtimeiso(new Date()),
      });
    }
    const postObject = {
      appointment_id: apptId,
      appt_patient_id: appointment.patient_id,
      appt_doctor_id: appointment.appt_doctor_id,
      appt_address_id: appointment.appt_address_id,
      status: apptStatus,
      billing: appointment.billing,
      payment: payment,
      fees_paid: 0,
      total_amount: appointment.total_amount,
      updated_by: "website",
      updatedtm: new moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    console.log("OBJECT ON CANCEL", postObject);
    axios.post("/postnewappointment_reception_web", postObject).then(
      (response, apptObject) => {
        this.getdoctordetails(
          this.state.selectedDoctor,
          this.state.selectedAddress,
          this.props.date.date
        );
      },
      (error) => {
        //Error Instructions goes here
        alert("Unable to update appointment.Kindly reload the page.");
      }
    );
  }

  findView(
    selectedView,
    loadMore,
    docColorCodes,
    cancelAppt,
    rescheduleAppt,
    selectedCategory,
    isDayDataLoading,
    totalPages,
    selectedPage,
    appointments,
    apptDocId,
    apptAddId
  ) {
    let printingCoordinates = this.state.presciptionCoordinates;
    console.log(printingCoordinates);
    console.log(this.props.user.userData);
    console.log(selectedCategory);
    switch (selectedView) {
      case "dayview": {
        return (
          <DayView
            isLoading={isDayDataLoading}
            // isLoadingAppt = {this.state.isLoadingAppt}
            isMoreDataExist={totalPages > selectedPage}
            scan={(appointment) => this.scanHandler(appointment)}
            appointments={appointments}
            loadMore={(apptDocId, apptAddId, date, selectedCategory) =>
              loadMore(apptDocId, apptAddId, date, selectedCategory, true)
            }
            docColorCodes={docColorCodes}
            docPrintList={this.state.docPrintList}
            selectedCategory={selectedCategory}
            cancelAppt={(apptId, apptStatus, appointment) =>
              cancelAppt(apptId, apptStatus, appointment)
            }
            rescheduleAppt={(apptObject, type) =>
              rescheduleAppt(apptObject, type)
            }
            openPrintPage={(apptDetails) => this.handlePrintPage(apptDetails)}
            printDocList={this.state.printDocList}
            setpayment={(appointment) => {
              console.log("Appointment selected", appointment);
              //payment data
              const totalBilling = {
                docId: appointment.appt_doctor_id,
                apptId: appointment.appointment_id,
                patId: appointment.patient_id,
                due: appointment.due_amount,
                feePaid: appointment.fees_paid ? appointment.fees_paid : 0,
                totalAmount: appointment.fees_paid
                  ? appointment.total_amount
                  : 0,
                billing: appointment.billing,
                payment: appointment.payment,
              };
              this.props.setpreviouspayment(totalBilling);
              this.props.history.push("/reception/editpayment");
            }}
            showPrescription={(appointment) => {
              console.log("IMAGE PRESCRIPTIONS", appointment);
              let activeIndex = 0;
              console.log(appointment.presciptions.length > 0);
              // debugger;
              const prescriptions =
                appointment.presciptions.length > 0
                  ? [...appointment.presciptions[0].image_data_final]
                  : ["NA", "NA", "NA", "NA", "NA"];
              console.log(prescriptions);
              for (let i = 0; i < prescriptions.length; i++) {
                if (prescriptions[i] != "NA") {
                  activeIndex = i;
                  break;
                }
              }
              console.log(prescriptions);
              // debugger;
              this.setState({
                selectedAppt: appointment,
                isScannedModalActive: true,
                scannedUrl: prescriptions,
                fileName: prescriptions.map(
                  (item) => item[item.lastIndexOf("/")]
                ),
                isActivePreview: activeIndex,
              });
            }}
          />
        );
      }
      case "monthview": {
        return <MonthCalender />;
      }
    }
  }

  getDoctorPrescriptionCoordinates(docId, doctors) {
    //Array of Doctors (doctors)
    console.log(docId, doctors);
    return doctors.filter((doctor) => {
      return doctor.doctor_id === docId;
    })[0].prescription_coordinates;
  }

  handlePrintPage(apptDetails) {
    const docPrintList = this.props.printDetails.docPrintList;
    console.log(apptDetails);
    // debugger
    // const printingCoordinates=this.getDoctorPrescriptionCoordinates(apptDetails.doctor_id, docPrintList);
    this.props.storePrintingDetails(apptDetails);
    this.props.history.push("/printingpage");
  }

  handleAddAppointment() {
    this.props.history.push("/reception/createappointment/");
  }

  render() {
    // window.onclose(function(){

    // })
    console.log("dashboard props", this.props);
    console.log(localStorage.getItem("is_master"));
    let categoryName = null;
    switch (this.state.selectedCategory) {
      case 0: {
        categoryName = "Appointments";
        break;
      }
      case 1: {
        categoryName = "Patient Seen";
        break;
      }
      case 2: {
        categoryName = "Cancelled";
        break;
      }
    }
    const doctorList =
      this.props.user.userData &&
      this.props.user.userData.doctors &&
      this.props.user.docColorCodes
        ? this.props.user.userData.doctors.map((doctor, index) => (
            <DoctorProfile
              key={"doctor" + index}
              key={index + 1}
              id={index + 1}
              docName={doctor.doctor_fullnm}
              docImage={doctor.doctor_image}
              docSpecialization={doctor.doctor_speciality}
              patSeen={doctor.seen_patient}
              cancelledAppt={doctor.cancel_count}
              totalAppts={doctor.total_Appointment}
              selectedCategory={this.state.selectedCategory}
              upcommingCount={doctor.upcoming_appointment_count}
              colorCode={this.props.user.docColorCodes[doctor.doctor_id]}
              selectCategory={(category) => {
                this.props.storSelectedDoc(doctor);
                const date = this.datebycategory(
                  this.props.date.date,
                  category
                );
                this.setState({
                  selectedCategory: category,
                  activeDoctor: doctor.doctor_id,
                  selectedDocName: doctor.doctor_fullnm,
                });
                this.getdoctordetails(
                  localStorage.getItem("loginId"),
                  localStorage.getItem("addId"),
                  date
                );
              }}
            />
          ))
        : null;
    const expandedView =
      localStorage.getItem("is_master") === "true" ? "0" : "1";
    const allDocProfileView =
      this.props.user.userData &&
      this.state.individualDoctor === "multiclinic" ? (
        <AllDoctorProfile
          upcommingCount={this.props.user.userData.upcommingCount}
          cancelledCount={this.props.user.userData.cancelledCount}
          totalAppts={this.props.user.userData.totalApptCount}
          patSeenCount={this.props.user.userData.patSeenCount}
          selectedCategory={this.state.selectedCategory}
          selectCategory={(category) => {
            this.props.storSelectedDoc(null);
            const date = this.datebycategory(this.props.date.date, category);
            this.setState({
              selectedCategory: category,
              activeDoctor: localStorage.getItem("loginId"),
              selectedDocName: "All Doctors",
            });
            this.getdoctordetails(
              localStorage.getItem("loginId"),
              localStorage.getItem("addId"),
              date
            );
          }}
        />
      ) : null;

    return (
      <div className="dashboard">
        <div className="dashboardContainer">
          {/*Left Block*/}
          <div className="col1">
            <LeftBlock
              changedate={(date) => this.changeDate(date)}
              selectedCategory={this.state.selectedCategory}
              date={this.props.date.date}
              allDocProfileView={allDocProfileView}
              doctorList={doctorList}
              expandedView={expandedView}
            />
          </div>
          {/*Right Block*/}
          <div className="col2">
            <RightBlock
              handleAddAppointment={() => this.handleAddAppointment()}
              view={this.findView(
                this.props.match.params.calenderView,
                (apptDocId, apptAddId, date, selectedCategory, isLoad) =>
                  this.getappointments(
                    apptDocId,
                    apptAddId,
                    date,
                    selectedCategory,
                    isLoad
                  ),
                this.props.user.docColorCodes,
                (apptId, apptStatus, appointment) =>
                  this.cancelApptHandler(apptId, apptStatus, appointment),
                (apptObject, type) =>
                  this.rescheduleAppointmentHandler(apptObject, type),
                this.state.selectedCategory,
                this.state.isDayDataLoading,
                this.state.totalPages,
                this.state.selectedPage,
                this.props.appointments.appointments,
                this.props.user.userData
                  ? this.props.user.userData.docId
                  : null,
                this.props.user.userData ? this.props.user.userData.addId : null
              )}
              categoryName={categoryName}
              date={this.props.date.date}
              selectedCategory={this.state.selectedCategory}
              selectedDoctor={this.props.user.selectedDoctor}
              docColorCodes={this.props.user.docColorCodes}
              docPrintList={this.state.docPrintList}
              isDayDataLoading={this.state.isDayDataLoading}
              totalPages={this.state.totalPages}
              selectedPage={this.state.selectedPage}
              apptDocId={
                this.props.user.userData ? this.props.user.userData.docId : null
              }
              apptAddId={
                this.props.user.userData ? this.props.user.userData.addId : null
              }
              appointments={this.props.appointments.appointments}
              changedate={(date) => this.changeDate(date)}
              loadMore={(
                apptDocId,
                apptAddId,
                date,
                selectedCategory,
                isLoad
              ) =>
                this.getappointments(
                  apptDocId,
                  apptAddId,
                  date,
                  selectedCategory,
                  isLoad
                )
              }
              cancelAppt={(apptId, apptStatus) =>
                this.cancelApptHandler(apptId, apptStatus)
              }
              rescheduleAppt={(apptObject, apptType) =>
                this.rescheduleAppointmentHandler(apptObject, apptType)
              }
              editAppointment={(apptObject) =>
                this.editAppointmentHandler(apptObject)
              }
              subTotal={this.state.subTotal}
              showPayment={() =>
                this.setState({ isPaymentSummaryActive: true })
              }
              setQuickAdd={() => {
                this.props.setQuickAdd(true);
                this.props.history.push("/reception/createappointment/");
              }}
            />
          </div>
        </div>
        <div className="clear"></div>
        {(this.props.history.location = "/reception/dashboard/dayview") ? (
          <ToastContainer />
        ) : null}
        <ScanPreview
          className="previewModal"
          imageUrl={this.state.scannedUrl}
          isModalActive={this.state.isScannedModalActive}
          discardUpload={(event, type) => {
            console.log("Discard Type is", type);
            const isConfirmend = window.confirm("Do you want to leave ?");
            if (this.state.selectedCategory === 0 && type !== "kill") {
              if (isConfirmend) {
                this.setState({
                  isabandoned: true,
                  numberOfScannedFiles: 0,
                  isScannedModalActive: false,
                  fileName: ["NA", "NA", "NA", "NA", "NA"],
                  scannedUrl: ["NA", "NA", "NA", "NA", "NA"],
                });
              }
            } else {
              if (isConfirmend) {
                this.setState({
                  numberOfScannedFiles: 0,
                  fileName: ["NA", "NA", "NA", "NA", "NA"],
                  scannedUrl: ["NA", "NA", "NA", "NA", "NA"],
                  isScannedModalActive: false,
                });
              }
            }
          }}
          submitAppt={() => {
            if (this.state.selectedCategory === 0) {
              this.submitApptHandler();
            } else {
              this.postprescriptions(this.state.selectedAppt);
            }
          }}
          addMore={() => this.scanHandler(this.state.selectedAppt)}
          isActive={this.state.isActivePreview}
          showImage={(imgNo) => {
            this.setState({ isActivePreview: imgNo });
          }}
          deletePreview={(activeIndex) =>
            this.deletePreviewHandler(activeIndex)
          }
          scanInProgress={this.state.scanInProgress}
          scanState={this.state.scanState}
          patientName={
            this.state.selectedAppt
              ? this.state.selectedAppt.patient_fullnm
              : null
          }
          selectedCategory={this.state.selectedCategory}
          apptInProgress={
            this.state.scanInProgress || this.state.submitApptInProgress
          }
        />
        {this.state.paymentByModes ? (
          <PaymentSummary
            totalPayment={this.state.paymentByModes}
            isModalActive={this.state.isPaymentSummaryActive}
            hideSummary={() => this.setState({ isPaymentSummaryActive: false })}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    date: state.date,
    appointments: state.appointmentData,
    doctorsData: state,
    printDetails: state.printDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rescheduleAppt: (apptObject, apptType) =>
      dispatch({
        type: "APPT_TO_RESCHEDULE",
        appointment: apptObject,
        apptType: apptType,
      }),
    setQuickAdd: (isTrue) =>
      dispatch({ type: "SET_QUICK_ADD", isQuickAdd: isTrue }),
    onDateChange: (date) => dispatch({ type: "DATE_CHANGE", value: date }),
    storeUpcomingAppointments: (appointments) =>
      dispatch({ type: "STORE_UPCOMING_APPTS", appointments: appointments }),
    userData: (userData) =>
      dispatch({ type: "STORE_DOCTORS_DATA", userData: userData }),
    setColorCodes: (colorCodes) =>
      dispatch({ type: "STORE_COLOR_CODES", colorCodes: colorCodes }),
    storSelectedDoc: (doctor) =>
      dispatch({ type: "STORE_SELECTED_DOCTOR", doctor: doctor }),
    setpreviouspayment: (payment) =>
      dispatch({ type: "STORE_PREVIOS_PAYMENT", payment: payment }),
    storePrintingDetails: (apptDetails) =>
      dispatch({ type: "STORE_PRINTING_DETAILS", patDetails: apptDetails }),
    setDocPrintList: (docPrintList) =>
      dispatch({ type: "SET_DOC_PRINTLIST", docPrintList: docPrintList }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
