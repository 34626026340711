import React, { Component } from "react";
import "./SelectPatient.css";
// import NewPatFont from '../../../assets/images/new_patient_font.png';
import ExistiongPatFont from "../../../assets/images/existing_patient_font.png";
import PatientCard from "../../../components/PatientCard/PatientCard";
import LeftArrowIcon from "../../../assets/images/icons/left-arrow.svg";
import RightArrowIcon from "../../../assets/images/icons/right-arrow.png";
import spinner from "../../../assets/images/spinner.gif";
import axios from "../../../axios-order";
import { Dropdown } from "react-bootstrap";
import PatientDetails from "../../../components/PatientDetails/PatientDetails";
import { connect } from "react-redux";
import previousIcon from "../../../assets/images/icons/previous.png";
import { localtimeiso } from "../../../assets/js/date_conversions";
import {
  checkforname,
  checkfornumber,
} from "../../../assets/js/validation_functions";

class SelectPatient extends Component {
  state = {
    recommendedPatients: null,
    selectedSearchCategory: "number",
    searchBy: "",
    isDetailsActive: false,
    patientDetails: this.props.user.patientData,
    isNumberValid: false,
    phoneNumber: "",
    isPatientFound: false,
    isNewPatientSearch: false,
    isExistingPatientSearch: false,
  };

  // componentDidMount() {
  //     if(localStorage.getItem('is_quick_mode')){
  //         this.props.patientSelect({
  //             "sex" : null,
  //             "patient_fullnm":"",
  //             "patient_id" : null,
  //             "bloodgroup" :null,
  //             "patient_phone_number" : "",
  //             "patient_age" : "-1",
  //             "patient_dob" : "1970-01-01",
  //             "patient_address" : {
  //                 district: ''
  //             }
  //     }, true)
  //     }
  // }

  handleCategoryClick(category) {
    // debugger;
    console.log("pat1", category);
    const screen1 = document.getElementById("newPatientScreen");
    const screen2 = document.getElementById("existingPatScreen");
    if (
      category === "existingPat" &&
      !screen1.classList.contains("widthHalf")
    ) {
      document.getElementById("combinedContainer").style.transform =
        "translateX(-50%)";
      document
        .getElementById("existingPatContainer")
        .classList.add("animatedFocusBlock");
      // document.getElementById('patientSelect').style.transform = "translateX(0%)";
      screen1.classList.add("zeroIndex");
      if (this.state.selectedSearchCategory == "number") {
        this.setState({ selectedSearchCategory: "patient name" });
      }
    }
    screen1.classList.add("widthHalf");
    screen2.classList.add("widthHalf");
    window.setTimeout(function () {
      const catSwitch1 = document.getElementById("existingPatSwitch");
      const catSwitch2 = document.getElementById("newPatSwitch");
      catSwitch1.classList.add("visible");
      catSwitch2.classList.add("visible");
    }, 500);
  }

  switchScreenHandler(category, keyPressed = "") {
    // debugger;
    const screen2 = document.getElementById("existingPatScreen");
    if (keyPressed === "Tab") {
      //   debugger;
      if (category === "existingPat") {
        // debugger;
        this.setState({
          selectedSearchCategory: "patient name",
          recommendedPatients: null,
          phoneNumber: "",
        });
      } else {
        this.setState({
          selectedSearchCategory: "number",
          recommendedPatients: null,
          searchBy: "",
        });
      }
      return null;
    }
    if (category === "newPat") {
      this.setState({
        selectedSearchCategory: "number",
        recommendedPatients: null,
        searchBy: "",
      });
      document.getElementById("combinedContainer").style.transform =
        "translateX(0)";
      screen2.style.zIndex = "-1";
      //combinedContainer.style.transform = "translateX(50%)";
    } else {
      this.setState({
        selectedSearchCategory: "patient name",
        recommendedPatients: null,
        phoneNumber: "",
      });
      screen2.style.zIndex = "10";
      screen2.style.position = "relative";
      document.getElementById("existingPatContainer").style.backgroundColor =
        "#fff";
      document.getElementById("combinedContainer").style.transform =
        "translateX(-50%)";
    }
  }

  searchHandler(value, category) {
    console.log(value);
    let searchType = null;
    switch (category) {
      case "number": {
        const isValid = checkfornumber(value);
        if (isValid) {
          if (value.length === 10) {
            searchType = 1;
            this.setState({ isNumberValid: true });
          } else if (parseInt(value.charAt(0)) < 6) {
            value = "";
          } else {
            this.setState({ isNumberValid: false, recommendedPatients: null });
          }
          this.setState({ phoneNumber: value });
        }
        break;
      }

      case "patient name": {
        console.log(value.length, value.charAt(0));
        if (checkforname(value) && value.charAt(0) != " ") {
          console.log("I m in check for name");
          if (value.length > 2) {
            searchType = 3;
          } else {
            console.log("else value");
            this.setState({ recommendedPatients: null });
          }
          this.setState({ searchBy: value });
        }

        break;
      }

      case "patient id": {
        if (value.charAt(0) != " ") {
          if (value.length > 0) {
            searchType = 2;
          } else {
            this.setState({ recommendedPatients: null });
          }
          this.setState({ searchBy: value });
        }
        break;
      }

      case "phone number": {
        debugger;
        if (checkfornumber(value) && value.charAt(0) != " ") {
          if (value.length === 10) {
            searchType = 1;
            this.setState({ isNumberValid: true });
          } else {
            this.setState({ isNumberValid: false, recommendedPatients: null });
          }
          this.setState({ searchBy: value });
        }

        break;
      }
      default:
        return searchType;
    }

    if (searchType) {
      const requestData = {
        search_value: value,
        doctor_id: this.props.docSelected,
        search_type: searchType,
      };
      this.setState({
        isPatientFound: true,
      });
      axios.post("/getPatientSearch_reception_web", requestData).then(
        (response) => {
          console.log("vv22", response);
          response.data.patient_search.sort((firstOrder, secondOrder) => {
            // console.log(parfirstOrder.patient_fullnm - secondOrder.patient_fullnm);
            if (firstOrder.patient_fullnm < secondOrder.patient_fullnm) {
              return -1;
            }
            console.log(
              "aaa",
              firstOrder.patient_fullnm,
              secondOrder.patient_fullnm
            );
            if (firstOrder.patient_fullnm > secondOrder.patient_fullnm) {
              return 1;
            }
            return 0;
          });
          if (searchType === 3) {
            this.setState({
              recommendedPatients:
                this.state.searchBy && this.state.searchBy.length > 2
                  ? response.data.patient_search
                  : null,
              isPatientFound: false,
            });
          } else {
            this.setState({
              recommendedPatients: response.data.patient_search,
              isPatientFound: false,
            });
          }
        },
        (error) => {
          this.setState({
            isPatientFound: false,
          });
          //Error Instruction goes here
        }
      );
    } else {
      this.setState({
        recommendedPatientsbyNumber: null,
        recommendedPatientsbyOther: null,
      });
    }
  }

  patientSelectHandler(patientDetails) {
    this.setState({ patientDetails: patientDetails });
    this.props.patientSelect(patientDetails, false);
  }

  nameChangeHandler(value) {
    let updatedDetails = { ...this.state.patientDetails };

    updatedDetails.patient_fullnm = value;
    this.props.userData(updatedDetails);
  }

  changesearchbyHandler(type) {
    this.setState({ searchBy: "", selectedSearchCategory: type });
  }

  patientSearchKeyDownHandler(event, patientType) {
    console.log(event.key);
    if (event.key === "Tab") {
      this.switchScreenHandler(patientType, event.key);
    }
  }

  render() {
    console.log("doctorSelected", this.props.docSelected);
    let patientsRecommended = null;
    console.log("ff", this.state.recommendedPatients); //null
    if (
      this.state.recommendedPatients &&
      this.state.recommendedPatients.length > 0
    ) {
      console.log("Recommended patients " + this.state.recommendedPatients);
      patientsRecommended =
        this.state.recommendedPatients !== null
          ? this.state.recommendedPatients.map((patient, index) => (
              <div
                key={patient.patient_id + "_" + index}
                onClick={() => {
                  if (this.props.doctors.default_payment_add) {
                    //debugger;
                    const defaultService = {
                      count: 1,
                      createdtm: localtimeiso(new Date()),
                      updatedtm: localtimeiso(new Date()),
                      tax: "0",
                      price: this.props.doctors.consulting_fee,
                      service_type: "default",
                      service_id: 22012,
                      service_info: {
                        service_id: 22012,
                        service_name: "medical service",
                      },
                    };
                    this.props.selectedServices.push(defaultService);
                  }

                  if (patient.currentApointment.length == 0) {
                    this.props.patientSelect(patient, false);
                  }
                }}
              >
                <PatientCard patientDetails={patient} />
              </div>
            ))
          : null;
    }

    let searchPatient = null;
    console.log("vishesh30", this.state.patientDetails); //null
    if (!this.state.patientDetails) {
      searchPatient = (
        <div style={{ height: "88vh" }}>
          <div className="selectPatient" id="patientSelect">
            <div className="createApptHead">
              <img
                src={previousIcon}
                alt="Back"
                onClick={() => this.props.goBack()}
              />
              Choose Patient
            </div>

            <div class="combinedPatientMainContainer" id="combinedContainer">
              <div className="newPatientScreen" id="newPatientScreen">
                <div className="newPatientContainer">
                  <div className="newPatBlock">
                    <div
                      className="PatientTitle"
                      style={{
                        opacity:
                          this.state.selectedSearchCategory == "number"
                            ? 1
                            : 0.3,
                      }}
                    >
                      <p className="existPatFont">New Patient?</p>
                      {/* <img src={NewPatFont} alt="new patient title"/> */}
                    </div>
                    <div className="newPatientFormWrapper">
                      <div className="newPatientForm">
                        <label className="patPhoneNumber">
                          Enter phone number
                        </label>
                        <input
                          placeholder="Enter here"
                          maxLength="10"
                          onKeyDown={(event) =>
                            this.patientSearchKeyDownHandler(
                              event,
                              "existingPat"
                            )
                          }
                          onFocus={() => this.handleCategoryClick("newPat")}
                          onChange={(e) =>
                            this.searchHandler(e.target.value, "number")
                          }
                          value={this.state.phoneNumber}
                          onClick={() => this.handleCategoryClick("newPat")}
                        />
                        <div className="confirmationMessage">
                          <span>Don't have a mobile number?</span>
                          <p
                            onClick={() => {
                              if (this.props.doctors.default_payment_add) {
                                //debugger;
                                const defaultService = {
                                  count: 1,
                                  createdtm: localtimeiso(new Date()),
                                  updatedtm: localtimeiso(new Date()),
                                  tax: "0",
                                  price: this.props.doctors.consulting_fee,
                                  service_type: "default",
                                  service_id: 22012,
                                  service_info: {
                                    service_id: 22012,
                                    service_name: "medical service",
                                  },
                                };
                                this.props.selectedServices.push(
                                  defaultService
                                );
                              }
                              this.props.patientSelect(
                                {
                                  sex: null,
                                  patient_fullnm: "",
                                  patient_id: null,
                                  bloodgroup: null,
                                  patient_phone_number: "",
                                  patient_age: "-1",
                                  patient_dob: "1970-01-01",
                                  // "patient_address" : {
                                  //     district: ''
                                  // }
                                  city: "",
                                },
                                true
                              );
                            }}
                          >
                            Skip
                          </p>
                        </div>
                      </div>
                    </div>
                    {this.state.isNumberValid ? (
                      <div className="addNewPatientLink">
                        <p
                          // className="new"
                          href=""
                          onClick={() => {
                            if (this.props.doctors.default_payment_add) {
                              //debugger;
                              const defaultService = {
                                count: 1,
                                createdtm: localtimeiso(new Date()),
                                updatedtm: localtimeiso(new Date()),
                                tax: "0",
                                price: this.props.doctors.consulting_fee,
                                service_type: "default",
                                service_id: 22012,
                                service_info: {
                                  service_id: 22012,
                                  service_name: "medical service",
                                },
                              };
                              this.props.selectedServices.push(defaultService);
                            }

                            this.props.patientSelect(
                              {
                                sex: null,
                                patient_fullnm: "",
                                patient_id: null,
                                patient_dob: "1970-01-01",
                                patient_phone_number: this.state.phoneNumber,
                                // "patient_address" : {
                                //     district: ''
                                // }
                                city: "",
                              },
                              true
                            );
                          }}
                        >
                          <p className="new">Add New Patient?</p>
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="recommendations">
                    {patientsRecommended && patientsRecommended.length > 0
                      ? patientsRecommended
                      : null}
                  </div>
                </div>

                <div className="existPatientBar" id="existingPatSwitch">
                  <div
                    className="navigateButton rightNavigateButton"
                    onClick={() => this.switchScreenHandler("existingPat")}
                  >
                    <img src={LeftArrowIcon} alt="" />
                  </div>
                  <div className="barContent2">Search By</div>
                </div>
              </div>
              <div className="existingPatientScreen" id="existingPatScreen">
                <div className="newPatientBar" id="newPatSwitch">
                  <div
                    className="navigateButton leftNavigateButton"
                    onClick={() => this.switchScreenHandler("newPat")}
                  >
                    <img src={RightArrowIcon} alt="" />
                  </div>
                  <div className="barContent1">New Patient</div>
                </div>
                <div
                  className="existingPatientContainer"
                  id="existingPatContainer"
                >
                  <div className="newPatBlock">
                    <div
                      className="PatientTitle"
                      style={{
                        color:
                          this.state.selectedSearchCategory != "number"
                            ? "#37b1e6"
                            : "#e1e1e1",
                      }}
                    >
                      Search By
                    </div>
                    <div className="existingPatientFormWrapper">
                      <div className="existingPatientForm">
                        <label className="patPhoneNumber">
                          Search Patient by
                        </label>
                        <div className="inputGroup">
                          <input
                            type="text"
                            maxLength={
                              this.state.selectedSearchCategory ==
                              "phone number"
                                ? "10"
                                : "50"
                            }
                            placeholder="Enter Here"
                            onKeyDown={(event) =>
                              this.patientSearchKeyDownHandler(event, "newPat")
                            }
                            onFocus={() =>
                              this.handleCategoryClick("existingPat")
                            }
                            onChange={(e) =>
                              this.searchHandler(
                                e.target.value,
                                this.state.selectedSearchCategory
                              )
                            }
                            value={this.state.searchBy}
                            onClick={() =>
                              this.handleCategoryClick("existingPat")
                            }
                          />
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {this.state.selectedSearchCategory != "number"
                                ? this.state.selectedSearchCategory
                                : "patient name"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={() =>
                                  this.changesearchbyHandler("patient name")
                                }
                              >
                                Patient Name
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() =>
                                  this.changesearchbyHandler("phone number")
                                }
                              >
                                Phone Number
                              </Dropdown.Item>
                              {/* <Dropdown.Item href="#" onClick = {() => this.changesearchbyHandler('patient id')}>Patient Id</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <a href=""><span>Patient Name</span> <span className="dropdownIcon"></span></a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="recommendations">
                    {patientsRecommended && patientsRecommended.length > 0 ? (
                      patientsRecommended
                    ) : this.state.isPatientFound ? (
                      <div className="spinnerIcon">
                        <img src={spinner} />
                      </div>
                    ) : !patientsRecommended &&
                      this.state.searchBy.length > 2 ? (
                      "No Patient Found"
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      searchPatient = (
        <div>
          <div className="createApptHead">Choose Patient</div>
          <div>
            <PatientDetails
              selectedPatient={this.state.patientDetails}
              changeName={(value) => this.nameChangeHandler(value)}
              changeNumber={(value) => this.props.numberChange(value)}
              changeGender={(value) => this.props.genderChange(value)}
              changeAge={(value) => this.props.ageChange(value)}
              changeBloodGroup={(value) => this.props.bloodgroupChange(value)}
              changePurpose={(value) => this.props.purposeChange(value)}
            />
          </div>
        </div>
      );
    }

    return <div>{searchPatient}</div>;
  }
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => {
  return {
    userData: (userData) =>
      dispatch({ type: "STORE_DOCTORS_DATA", userData: userData }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPatient);
