import React from 'react';
import './SelectDoctorCard.css';
import doctorImageMale from '../../../../assets/images/default/doctor.svg';
import doctorImageFemale from '../../../../assets/images/default/doctorf.png';

function SelectDoctorCard(props) {
    // console.log(props.docImage);
    // debugger;
    const myStyle = {
        backgroundColor : props.color 
    }
    // console.log(props.docImage)
    // console.log('Props for SelectedDoctorCard', props);
    return (
        <div className="doctorCardContainer" onClick ={props.clicked}>
            <div className="doctorCardBlur"></div>
            <div className="doctorCardMainContent">
                <div className="doctorPhotoSection">
                    {(props.docImage!=='NA') ?<img src={props.docImage} alt="" /> : null}
                    {(props.docImage==='NA' && (props.docGender=='Male' || props.docGender=='male')) ? <img src={doctorImageMale} alt="" /> : null}
                    {(props.docImage==='NA' && (props.docGender=='Female' || props.docGender=='female')) ? <img src={doctorImageFemale} alt="" /> : null}
                </div>
                <div className="doctorCardSeperator"></div>
                <div className="doctorCardDetails">
                    <div className="doctorName">
                        {props.docName}
                    </div>
                    <div className="doctorSpec">
                        {props.docSpecialization}
                    </div>
                </div>    
            </div>            
        </div>
    )
}

export default SelectDoctorCard