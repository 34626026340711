import React, { useState } from "react";
import DeleteRequestForm from "./DeleteRequestForm";
import SubmitMessage from "./SubmitMessage";
import Progress from "./Progress";
import "./DeleteProfile.css";

const DeleteProfileRequest = () => {
  const [msg, setMsg] = useState(0);

  const renderComponent = () => {
    switch (msg) {
      case 1:
        return <Progress />;
        
      case 2:
        return <SubmitMessage />;

      default:
        return <DeleteRequestForm
          setMessage={(value) => {
            setMsg(value);
          }}
        />;
    }
  }
  return (
    <div className="deleteScreenContainer">
      {renderComponent()}
    </div>
  );
};
export default DeleteProfileRequest;
