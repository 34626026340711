import React, { useEffect, useState } from "react";
import paymentStyle from "./Payment.module.css";
import axios from "../../axios-order";
import plus_minus from "../../assets/images/icons/plus_minus.svg";
import minus_final from "../../assets/images/icons/minus_final.svg";
import Spinner from "../../assets/images/spinner.gif";
import { Modal } from "react-bootstrap";
import { localtimeiso } from "../../assets/js/date_conversions";

function PaymentCard({
  handleCallBilling,
  handleCallPayment,
  doctorId,
  handleCallSelectedService,
  handleTempView,
  updateSelectedService,
  paymentObject,
  billingObjects,
  isReschedule,
  tempView,
}) {
  const [doctorService, setDoctorServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(
    updateSelectedService
  );
  const [isLoader, setIsLoader] = useState(false);
  const [showAlert, setShowAlert] = useState({
    show: false,
    errorMsg: "",
  });
  const [template, setTemplate] = useState(tempView);

  //get the data from api

  useEffect(() => {
    const getServicesHandler = async () => {
      setIsLoader(true);
      const requestBody = { getservices: { doctor_id: doctorId } };

      try {
        const response = await axios.post(
          "/postgetconfiguredconsultation_treatments",
          requestBody
        );
        console.log("responseData", response);
        const configureServices = response.data.configured_consult_treatments;

        const addDoctorServices = [];

        if (configureServices.default) {
          addDoctorServices.push(
            {
              serviceHeading: {
                heading: "Default Service",
                count: 1,
              },
            },
            configureServices.default
          );
        }
        if (
          configureServices.consultations &&
          configureServices.consultations.length > 0
        ) {
          addDoctorServices.push(
            {
              serviceHeading: {
                heading: "Consultation",
                count: configureServices.consultations.length,
              },
            },

            ...configureServices.consultations
          );
        }

        if (
          configureServices.treatments &&
          configureServices.treatments.length > 0
        ) {
          addDoctorServices.push(
            {
              serviceHeading: {
                heading: "Treatments",
                count: configureServices.treatments.length,
              },
            },

            ...configureServices.treatments
          );
        }

        if (
          configureServices.vaccinations &&
          configureServices.vaccinations.length > 0
        ) {
          addDoctorServices.push(
            {
              serviceHeading: {
                heading: "Injections",
                count: configureServices.vaccinations.length,
              },
            },
            ...configureServices.vaccinations
          );
        }
        if (addDoctorServices.length === 0) {
          addDoctorServices.push("No Service");
        }
        setDoctorServices(addDoctorServices);

        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
        console.log(error);
        setShowAlert((prev) => ({
          ...prev,
          show: true,
          errorMsg: error.message,
        }));
      }
    };

    getServicesHandler();
  }, []);

  console.log("DefaultSelected", selectedServices);

  const handleAddService = (serviceId) => {
    console.log("handleAddService");
    debugger;
    const updatedSelectedServices = [...selectedServices];
    const serviceFound = updatedSelectedServices.find(
      (service) => service.service_info.service_id === serviceId
    );
    console.log("serviceFound ", serviceFound);

    if (serviceFound) {
      if (serviceFound.count === 100) {
      } else {
        serviceFound.count = serviceFound.count + 1;
        serviceFound.updatedtm = localtimeiso(new Date());
      }

      setSelectedServices(updatedSelectedServices);
    } else {
      const findServiceObject = doctorService.find(
        (service) => service.service_info?.service_id === serviceId
      );

      if (findServiceObject.service_type === "consultation") {
        // debugger;
        const existConsultationIndex = updatedSelectedServices.findIndex(
          (service) => findServiceObject.service_type === service.service_type
        );

        console.log("existIndex", existConsultationIndex);

        if (existConsultationIndex !== -1) {
          updatedSelectedServices.splice(existConsultationIndex, 1);
        }
      }

      if (findServiceObject) {
        updatedSelectedServices.push({
          ...findServiceObject,
          count: 1,
          updatedtm: localtimeiso(new Date()),
          createdtm: localtimeiso(new Date()),
        });
        //debugger;
        setSelectedServices(updatedSelectedServices);
      }
    }
  };

  useEffect(() => {
    handleCallBilling(billingObjects);
  }, [selectedServices]);

  useEffect(() => {
    handleCallSelectedService(selectedServices);
  }, [selectedServices]);

  // remove services

  const handleRemoveService = (serviceId) => {
    console.log("handleRemoveService");
    const updatedSelectedServices = [...selectedServices];
    const finalServiceList = updatedSelectedServices.filter((service) => {
      if (service.service_info.service_id === serviceId) {
        if (service.count == 1) {
          return false;
        }
        service.count = service.count - 1;
      }
      return true;
    });
    console.log("updatedSelected", finalServiceList);
    setSelectedServices(finalServiceList);
  };

  // billingObjects = selectedServices
  //   .filter((service) => service.count > 0)
  //   .map((service) => ({
  //     created_by: "reception",
  //     price: service.price,
  //     service_name: service.service_info.service_name,
  //     updated_by: "reception",
  //     units: service.count,
  //     updatedtm: service.updatedtm,
  //     service_id: service.service_info.service_id,
  //     createdtm: service.createdtm,
  //     tax: service.tax.replace("%", ""),
  //     service_type: service.service_type,
  //   }));

  // console.log("billing object", billingObjects);

  // calculate the total amount of services

  const totalPrice = () => {
    let total = 0;
    selectedServices.forEach((item) => {
      const p = item.price * item.count;
      total += p;
    });
    return total;
  };

  const handleClose = () => {
    if (tempView === 2) {
      setTemplate(0);
    } else {
      setShowAlert((prev) => ({
        ...prev,
        show: false,
      }));
    }
  };

  // paymentObject = [
  //   {
  //     amount: totalPrice(),
  //     payment_mode: "cash",
  //     is_service: true,
  //     updatedtm: localtimeiso(new Date()),
  //     collected_by: "reception",
  //     is_refund: "false",
  //     collectdtm: localtimeiso(new Date()),
  //   },
  // ];
  console.log("paymentObject", paymentObject);

  useEffect(() => {
    handleCallPayment(paymentObject);
  }, [selectedServices]);

  useEffect(() => {
    handleTempView(template);
  }, [template]);

  // calculate total services

  const totalCount = () => {
    let count = 0;
    selectedServices.forEach((item) => {
      count += item.count;
    });
    return count;
  };

  const items = () => {
    let itemName = "";
    if (totalCount() > 1) {
      itemName = "services";
    } else {
      itemName = "service";
    }
    return itemName;
  };

  return (
    <div className={paymentStyle.container}>
      <div className={paymentStyle.showTotalPrice}>
        <span className={paymentStyle.countService}>
          {totalCount()}{" "}
          <span className={paymentStyle.showServiceCount}>
            Selected {items()}
          </span>
        </span>
        <span className={paymentStyle.totalServiceCount}></span>
        <span className={paymentStyle.totalServicePrice}>
          Sub-total : <span className={paymentStyle.icons}>&#x20B9;</span>{" "}
          {totalPrice()}
        </span>
      </div>

      <div className={paymentStyle.service}>
        {isLoader ? (
          <div className={paymentStyle.shiftLoader}>
            <img src={Spinner} alt="" />
          </div>
        ) : null}
        {doctorService.map((item, index) => {
          const serviceId = item.service_info
            ? item.service_info.service_id
            : null;

          const selectService = selectedServices.find(
            (service) =>
              service.service_info.service_id === serviceId && service.count > 0
          );

          const count = selectService ? selectService.count : 0;

          return (
            <div>
              {item.serviceHeading ? (
                <div key={index} className={paymentStyle.consult}>
                  {item.serviceHeading.heading}
                  <span className={paymentStyle.count}>
                    ({item.serviceHeading.count})
                  </span>
                </div>
              ) : item.service_info ? (
                <div key={index} className={paymentStyle.doctorsService}>
                  <div className={paymentStyle.displayService}>
                    {item.service_info && item.service_info.service_name}
                  </div>
                  <div className={paymentStyle.displayPrice}>
                    <span className={paymentStyle.icons}>&#x20B9;</span>
                    {item.price}
                  </div>
                  <div className={paymentStyle.gridColumn3}>
                    {selectService &&
                      (item.service_type === "consultation" ||
                      item.service_type === "default" ? (
                        <button
                          className={
                            isReschedule
                              ? paymentStyle.disableAddService
                              : paymentStyle.addServiceBtn
                          }
                          disabled={isReschedule}
                          onClick={() => handleRemoveService(serviceId)}
                        >
                          Remove
                        </button>
                      ) : (
                        <div
                          className={
                            isReschedule
                              ? paymentStyle.disabledButtonAdd
                              : paymentStyle.increment
                          }
                          disabled={isReschedule}
                        >
                          <span>
                            <img
                              src={minus_final}
                              alt=""
                              className={
                                isReschedule
                                  ? paymentStyle.disabledMinus
                                  : paymentStyle.minusBtn
                              }
                              disabled={isReschedule}
                              onClick={() => handleRemoveService(serviceId)}
                            />
                          </span>
                          <span
                            className={
                              isReschedule
                                ? paymentStyle.disableCounter
                                : paymentStyle.counter
                            }
                          >
                            {count}
                          </span>
                          <span>
                            <img
                              src={plus_minus}
                              alt=""
                              className={
                                isReschedule
                                  ? paymentStyle.disabledPlus
                                  : count === 100
                                  ? paymentStyle.removeButton
                                  : paymentStyle.plusBtn
                              }
                              disabled={isReschedule}
                              onClick={() => handleAddService(serviceId)}
                            />
                          </span>
                        </div>
                      ))}

                    {!selectService && (
                      <button
                        className={
                          isReschedule
                            ? paymentStyle.disableAddService
                            : paymentStyle.addServiceBtn
                        }
                        disabled={isReschedule}
                        onClick={() => handleAddService(serviceId)}
                      >
                        Add
                      </button>
                    )}
                  </div>
                  <div className={paymentStyle.path}></div>
                </div>
              ) : (
                <div className={paymentStyle.noService}>
                  {" "}
                  <h1>{item}</h1>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Modal show={showAlert.show}>
        <Modal.Header error>
          <Modal.Body>
            <h3>{showAlert.errorMsg}</h3>
          </Modal.Body>
          <Modal.Footer>
            <button variant="outlined" onClick={handleClose}>
              OK
            </button>
          </Modal.Footer>
        </Modal.Header>
      </Modal>
    </div>
  );
}

export default PaymentCard;
