import React from 'react';

function TimeShift(props) {
    // console.log(props);
    return (
        <div className={(props.isSelected) ? 'activeShift shiftSlot' : 'shiftSlot'} onClick = {() => props.click(props.startTime, props.endTime)}>
            <span class="shiftStart">{props.startTime}</span> to <span class="shiftEnd">{props.endTime}</span>
        </div>
    )
}

export default TimeShift