export const sortByDoctorName = (doctorList) => {
    return doctorList.sort((a, b)=>{
        
        if(a.doctor_fullnm > b.doctor_fullnm){
            return 1
        }
        if(b.doctor_fullnm > a.doctor_fullnm){
            return -1
        }
        return 0
    })
}