export const findBloodGroup = (bloodGroup) => {
    switch (bloodGroup) {
        case 1:
            return "A+";
        case 2:
            return "A-";
        case 3:
            return "B+";
        case 4:
            return "B-";
        case 5:
            return "O+";
        case 6:
            return "O-";
        case 7:
            return "AB+";
        case 8:
            return "AB-";
        default:
            return null;
    }
}

export const inchtofeet = (valueInInch) => {

}

export const convertIntoDecimal = (value1,value2) => {
    // debugger
    let value = '0';
    // debugger;
    if(!value2){
        value2 = 0
    }
    if(!value1){
        value1 = 0
    }
    value = value1+'.'+value2;
    if(value2>9 && value2<99){
        return parseFloat(value).toFixed(2);
    }else if(value2>99){
        return parseFloat(value).toFixed(3);
    }else{
        return parseFloat(value);
    }
}

export const convertVitalInMm = (vitalInCm, vitalInMm) => {
    if(!(vitalInCm>=0 && vitalInMm>=0)){
        return null
    }
    return vitalInCm*10+vitalInMm;
}

export const cmtofeetinches = (n) => {
    
    // const realInches = Math.ceil(n/2.54);
    // const realInches = n/2.54;
    const realFeet = n/30;
    // console.log('Real inches', realInches);
    // const feet = parseInt(realInches/12);
    // const inches = Math.ceil(realInches%12);
    // console.log(realFeet%30);
    // cosnt inch = realFeet%30;
    return {feet : parseInt(realFeet), inch : Math.floor((n%30)/2.54)};
}

export const convertToCmMm = (mmHeight) => {
    const mm = mmHeight%10;
    const cm = parseInt(mmHeight/10);
    console.log(mm,cm);
    return {mm:mm, cm:cm}
}

export const calculateage = (dob, age) => {
    let result = null;
    // console.log(new Date(dob).getTime()!==0);
    if(dob && new Date(dob).getTime()!==0){
        //Calculate age and return
        const ageDifMs = Date.now() - new Date(dob);   
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        result = Math.abs(ageDate.getUTCFullYear() - 1970);
        
    } else {
        if(age && age>-1){
            result = age;
        }
    }

    return result;
}
