import { combineReducers } from "redux";

const initialState = {
  isAuthenticated: false,
  authRedirectPath: "/",
  userData: null,
  patientData: null,
  docColorCodes: null,
  selectedDoctor: null,
};

const dateState = {
  date: new Date(),
};

const paymentState = {
  payment: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AUTHENTICATE_USER": {
      // console.log('Athenticating User with action', action);
      return {
        ...state,
        isAuthenticated: action.authenticated,
      };
    }
    case "STORE_DOCTORS_DATA": {
      // console.log("Storing doctors data with action as", action)
      return {
        ...state,
        userData: action.userData,
      };
    }
    case "STORE_PATIENT_DATA": {
      // console.log("Storing doctors data with action as", action);
      return {
        ...state,
        patientData: action.value,
      };
    }
    case "STORE_COLOR_CODES": {
      // console.log(action.colorCodes);
      return {
        ...state,
        docColorCodes: action.colorCodes,
      };
    }
    case "STORE_SELECTED_DOCTOR": {
      return {
        ...state,
        selectedDoctor: action.doctor,
      };
    }
    default:
      return state;
  }
};

const printReducer = (state = { printingDetails: null }, action) => {
  console.log(action);
  switch (action.type) {
    case "STORE_PRINTING_DETAILS": {
      console.log(state);
      console.log(
        state.docPrintList.filter((doctor) => {
          return doctor.doctor_id === action.patDetails.doctor_id;
        })
      );
      let docPrintList = state.docPrintList.filter((doctor) => {
        return doctor.doctor_id === action.patDetails.doctor_id;
      })[0];
      console.log(docPrintList);
      // debugger;
      return {
        ...state,
        printingDetails: action.patDetails,
        printCoordinates: docPrintList.prescription_coordinates,
        apptDate: dateState,
      };
    }

    case "SET_DOC_PRINTLIST": {
      return {
        ...state,
        docPrintList: action.docPrintList,
      };
    }

    // case 'SET_PRINT_COORDINATES' : {
    //     return {
    //         ...state,
    //         printCoordinates:
    //     }
    // }

    default:
      return state;
  }
};

const dateReducer = (state = dateState, action) => {
  switch (action.type) {
    case "INCREMENT_DATE": {
      return {
        ...state,
        date: new Date(dateState.getDate() + 1),
      };
    }
    case "DECREMENT_DATE": {
      return {
        ...state,
        date: new Date(dateState.getDate() - 1),
      };
    }
    case "DATE_CHANGE": {
      return {
        ...state,
        date: action.value,
      };
    }
    default:
      return state;
  }
  // return state
};

const appointmentReducer = (
  state = {
    appointments: null,
    rescheduleAppt: null,
    isConfirmed: false,
    apptType: null,
    isquikadd: false,
  },
  action
) => {
  // console.log('actionis', action)
  // console.log(action);
  switch (action.type) {
    case "STORE_UPCOMING_APPTS": {
      return {
        ...state,
        appointments: action.appointments,
      };
    }

    case "APPT_TO_RESCHEDULE": {
      console.log("here in appt reschedule");
      return {
        ...state,
        rescheduleAppt: action.appointment,
        apptType: action.apptType,
      };
    }

    case "SET_APPT_CONFIRMATION": {
      // console.log('here in appt reschedule');
      console.log(action.isConfirmed);
      // debugger;
      return {
        ...state,
        isConfirmed: action.isConfirmed,
      };
    }
    case "SET_QUICK_ADD": {
      // console.log('here in appt reschedule');
      console.log(state);
      console.log(action.isQuickAdd);
      // debugger;
      return {
        ...state,
        isQuickAdd: action.isQuickAdd,
      };
    }

    default: {
      return state;
    }
  }
  // return state
};

const paymentReducer = (state = paymentState, action) => {
  // console.log('actionis', action)
  switch (action.type) {
    case "STORE_PREVIOS_PAYMENT": {
      return {
        ...state,
        payment: action.payment,
      };
    }
    default:
      return state;
  }
  // return state
};

const allReducers = combineReducers({
  user: userReducer,
  date: dateReducer,
  appointmentData: appointmentReducer,
  payment: paymentReducer,
  printDetails: printReducer,
});

export default allReducers;
