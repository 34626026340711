import React from "react";
import "./SelectDoctor.css";
import SelectDoctorCard from "./SelectDoctorCard/SelectDoctorCard";
import previousIcon from "../../../assets/images/icons/previous.png";

function SelectDoctor(props) {
  console.log("vishesh3 props:", props);

  // console.log('Props for SelectDoctor component', props);
  const doctorCard = props.doctors.map((doctor, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          props.select(doctor);
        }}
      >
        <SelectDoctorCard
          color={doctor.colorCode}
          docImage={doctor.doctor_image}
          docName={doctor.doctor_fullnm}
          docSpecialization={doctor.doctor_speciality}
          docGender={doctor.sex}
        />
      </div>
    );
  });
  return (
    <div style={{ height: "88vh", overflow: "hidden" }}>
      <div className="selectDoctorContainer" style={{ height: "100%" }}>
        <div className="selectDoctor">
          <div className="createApptHead">
            <img src={previousIcon} alt="Back" onClick={() => props.goBack()} />
            Choose Doctor
          </div>
          <div className="doctorList">{doctorCard}</div>
        </div>
        {/* <CreateApptHeader /> */}
      </div>
    </div>
  );
}

export default SelectDoctor;
