import axios from "axios";
// const sessionKey=localStorage.getItem('sessionKey');
const instance = axios.create({
  // baseURL: "http://182.77.63.129:4013/"
  //baseURL: "http://182.77.63.129:4004/",
  baseURL: "https://api.athancare.com/",
  // baseURL : 'http://192.168.1.150:4004/'
});

instance.defaults.headers.common["Content-Type"] = "application/json";
// instance.defaults.headers.common["X-access-token"] = sessionKey;
instance.defaults.timeout = 50000;

export default instance;
