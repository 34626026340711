
export function sortAppointmentsByHour(appointmentsData, category){
    var obj = {};
    appointmentsData = appointmentsData.sort((a, b)=>{
        // new Date(a.appointment_datetime)));
        // debugger;
        // console.log(parseInt(new Date(a.appointment_datetime))>parseInt(new Date(b.appointment_datetime)));
        // console.log(a.appointment_datetime, new Date(a.appointment_datetime));
        // debugger;
        const time1 = new Date(new Date(a.appointment_datetime).toLocaleString("en-US", {timeZone: "Asia/Kolkata"}));
        const time2 = new Date(new Date(b.appointment_datetime).toLocaleString("en-US", {timeZone: "Asia/Kolkata"}));
        // return Date.parse(new Date(a.appointment_datetime))>Date.parse(new Date(b.appointment_datetime))
        return time1 > time2;
    })
    // console.log('Sorting order', appointmentsData);
    for(var i=0;i<24;i++){
        var arr = [];
        for(var j=0;j<appointmentsData.length;j++){
            if(category===0){
                if(new Date(appointmentsData[j].appointment_datetime.substr(0, appointmentsData[j].appointment_datetime.length-1)).getHours()===i){
                    arr.push(appointmentsData[j]);
                }
            }
            if(category===1){
                // console.log(appointmentsData[j].updatedtm);
                if(new Date(appointmentsData[j].appointment_datetime.substr(0, appointmentsData[j].appointment_datetime.length-1)).getHours()===i){
                    arr.push(appointmentsData[j]);
                }
            }
            
            if(category===2){
                if(new Date(appointmentsData[j].appointment_datetime.substr(0, appointmentsData[j].appointment_datetime.length-1)).getHours()===i){
                    arr.push(appointmentsData[j])
                }
            }   
        }
        if(arr.length){
            obj[''+i] = arr;
        }
    }

    if(Object.keys(obj).length===0)
        return null
    return obj;

}