import React, { Component } from "react";
import "./CreateAppointment.css";
// import { Route } from 'react-router-dom';
import { connect } from "react-redux";
import axios from "../../axios-order";
import moment from "moment";
import {
  cmtofeetinches,
  convertToCmMm,
  convertVitalInMm,
  calculateage,
} from "../../assets/js/calculate_details.js";

import previousIcon from "../../assets/images/icons/previous.png";
//Componets
import SelectDoctor from "./SelectDoctor/SelectDoctor";
import SelectPatient from "./SelectPatient/SelectPatient";
import SelectSlot from "./SelectSlot/SelectSlot";
import AppointmentSummary from "./AppointmentSummary/AppointmentSummary";

// import {Redirect} from 'react-router-dom';
import PatientDetails from "../../components/PatientDetails/PatientDetails";
// import PatientVitals from '../../components/PatientVitals/PatientVitals';
import {
  checkforname,
  checkfornumber,
  checkforcity,
} from "../../assets/js/validation_functions";
import { localtimeiso } from "../../assets/js/date_conversions";
import { Modal } from "react-bootstrap";

// function QuickPayment({changeTab}) {
//     return (
//         <div>
//             <div className = "createApptHead">
//                 <img src={previousIcon} alt="Back" onClick={()=>this.props.goBack()}/>
//                 Patient Details
//             </div>
//             <div className="mainContainer">
//             <div className="pd-tabs">
//                 <div onClick={()=>changeTab(3)}>Basic</div>
//                 <div onClick={()=>changeTab(4)}>Vitals</div>
//                 <div className="activeDetails">Payment</div>
//             </div>
//             </div>
//         </div>
//     )
// }

class CreateAppointment extends Component {
  state = {
    bookingData: null,
    isValid: true,
    existedApptTime: "",
    reschModalActive: false,
    progressAppt: false,
    isReshedule: false,
    apptSummaryStep: 0,
    isNameValid: true,
    isCityValid: true,
    isNumberValid: true,
    bookedAppointments: {},
    selectedDate: null,
    selectedSlot: null,
    selectedDoctor: null,
    selectedPatient: null,
    patientAppointmentData: null,
    previousPayment: 0,
    docSelected: null,
    addressId: null,
    isNew: true,
    view: null,
    feePaid: "0",
    totalAmount: "0",
    payment: [],
    billing: [],
    // services: [],
    due: "0",
    otherApptInfo: {
      isRevisit: false,
      height: null,
      heightCm: null,
      heightInMm: null,
      heightMm: null,
      heightFeet: null,
      heightInch: null,
      weightKg: null,
      weightGm: null,
      weight: null,
      temprature: null,
      lowbp: null,
      highbp: null,
      pulse: null,
      respiratory: null,
      spotwo: null,
      bmi: null,
      headCircumference: null,
      headCircumferenceCm: null,
      headCircumferenceMm: null,
      tsf: null,
      muac: null,
      ssf: null,
      appointment_type: "Normal",
    },
    paymentArray: [],
    isDobUpdate: true,
    selectedServices: [],
  };

  //Set Doctor Id and Address Id for state an
  selectDoctorHandler(doctor) {
    console.log("vishesh4: ", doctor);
    console.log("vishesh4.5: ", this.state);
    const docId = doctor.doctor_id;
    const addId = localStorage.getItem("addId");
    doctor.express_fee = doctor.express_fee ? doctor.express_fee : 0;
    this.setState({
      selectedDoctor: doctor,
      docSelected: docId,
      addressId: addId,
      view: this.props.appointments.apptType !== "edit" ? 1 : 3,
      selectedDate: this.props.date.date,
    });
    console.log("vishesh5 ", this.state);
  }

  setFieldTrue(fieldName) {
    console.log("Field name is ", fieldName);
    switch (fieldName) {
      case "name":
        this.setState({
          isNameValid: true,
        });
        break;

      case "city":
        this.setState({
          isCityValid: true,
        });
        break;
    }
  }

  callbackBilling = (childData) => {
    console.log("Check DataBilling", childData);
    this.setState({ billing: childData });
  };

  callBackPayment = (child) => {
    this.setState({ payment: child });
    console.log("DataPayment", child);
  };

  callBackSelectService = (child) => {
    this.setState({ selectedServices: child });
    console.log("Rest", child);
  };

  componentDidMount() {
    console.log(this.props);
    window.scrollTo(0, 0);

    console.log("this props", this.props.appointments);
    if (this.props.appointments.rescheduleAppt) {
      const resAppt = this.props.appointments.rescheduleAppt;
      console.log("RES APPPT", resAppt);
      const apptObject = {
        ...this.state.otherApptInfo,
        highbp: resAppt.h_bp,
        lowbp: resAppt.l_bp,
        weight: resAppt.weight,
        temprature: resAppt.temperature,
        appointment_id: resAppt.appointment_id,
        isRevisit: resAppt.purposeofvisit == "Revisit",
        spotwo: resAppt.spotwo,
        pulse: resAppt.pulse,
        respiratory: resAppt.respiratory,
        bmi: resAppt.bmi === "0" ? null : resAppt.bmi,
        appointment_type: resAppt.appointment_type
          ? resAppt.appointment_type
          : "Normal",
        ssf: resAppt.ssf,
        tsf: resAppt.tsf,
        muac: resAppt.muac,
      };
      console.log(apptObject);
      console.log(convertToCmMm(1505));
      if (resAppt.height != null && resAppt.height >= 0) {
        apptObject.height = resAppt.height;
        apptObject.heightCm = convertToCmMm(resAppt.height).cm;
        apptObject.heightMm = convertToCmMm(resAppt.height).mm;
        apptObject.heightFeet = cmtofeetinches(resAppt.height).feet;
        apptObject.heightInch = cmtofeetinches(resAppt.height).inch;
      }
      if (resAppt.weight !== null && resAppt.weight !== undefined) {
        apptObject.weight = resAppt.weight;
        apptObject.weightGm = resAppt.weight % 1000;
        apptObject.weightKg = parseInt(resAppt.weight / 1000);
      }
      if (
        resAppt.head_circumference !== null &&
        resAppt.head_circumference !== undefined
      ) {
        apptObject.headCircumference = resAppt.head_circumference;
        apptObject.headCircumferenceMm = resAppt.head_circumference % 10;
        apptObject.headCircumferenceCm = parseInt(
          resAppt.head_circumference / 10
        );
      }
      console.log("Rest App", resAppt);
      // debugger;
      const patObject = {
        bloodgroup: resAppt.bloodgroup,
        patient_age: resAppt.patient_age,
        patient_fullnm: resAppt.patient_fullnm,
        patient_phone_number: resAppt.patient_phone_number,
        sex: resAppt.sex,
        patient_id: resAppt.patient_id,
        city: resAppt.city,
        // "patient_address": {
        //     district: (resAppt.patient_address)?resAppt.patient_address.district:''
        // }
      };
      if (new Date(resAppt.patient_dob).getTime() == 0) {
        patObject.patient_dob = "";
      } else {
        patObject.patient_dob = resAppt.patient_dob;
        this.setState({ isDobUpdate: false });
      }

      const updatedString1 = resAppt.appointment_datetime.replace(/T/, " ");
      const updatedString2 = updatedString1.replace(/Z/, "");
      const selectedSlot = Date.parse(new Date(updatedString2));
      console.log(selectedSlot);
      console.log("RRst billing", resAppt.billing);
      console.log("RRst", resAppt.payment);
      this.setState({
        billing: resAppt.billing,
        payment: resAppt.payment,
        totalAmount: resAppt.total_amount,
        feePaid: resAppt.fees_paid,
        previousPayment: parseInt(resAppt.total_amount),
        selectedSlot:
          this.props.appointments.apptType === "edit" ? selectedSlot : null,
        isReshedule: true,
        selectedPatient: patObject,
        otherApptInfo: apptObject,
        paymentArray: resAppt.payment,
        view: 2,
      });
      this.selectDoctorHandler(
        this.props.user.userData.doctors.filter((item) => {
          if (item.doctor_id === resAppt.appt_doctor_id) return true;
        })[0]
      );

      this.setState({
        selectedServices: resAppt.billing.map((item) => ({
          count: item.units,
          createdtm: item.createdtm,
          lastupdated: localtimeiso(new Date()),
          tax: item.tax + "%",
          price: item.price,
          service_type: item.service_type,
          service_id: item.service_id,
          service_info: {
            service_id: item.service_id,
            service_name: item.service_name,
          },
        })),
      });
      console.log("selected", this.state.selectedServices);
    } else {
      if (!this.props.user.userData) this.props.history.push("/");
      else if (this.props.user.selectedDoctor) {
        this.selectDoctorHandler(this.props.user.selectedDoctor);
      } else if (this.props.appointments.isQuickAdd) {
        // alert('here');
        this.selectDoctorHandler(this.props.user.userData.doctors[0]);
      } else {
        this.setState({ view: 0, selectedDate: this.props.date.date });
      }
      // else if(!this.props.user.userData.isHospital){
      //     this.selectDoctorHandler(this.props.user.userData);
      // } else {
      //     this.setState({view:0, selectedDate:this.props.date.date})
      // }
    }
  }

  // convertVitalInMm(vitalInCm, vitalInMm){
  //     // debugger;
  //     // let otherApptInfo = {...this.state.otherApptInfo};
  //     // let dimension = null;
  //     if(!(vitalInCm>=0 && vitalInMm>=0)){
  //         return null
  //     }
  //     return vitalInCm*10+vitalInMm;

  //     // // console.log(otherApptInfo);
  //     // if(vitalInCm>=0 && vitalInMm>=0){
  //     //     if(vitalType==='height'){
  //     //         // otherApptInfo.height=vitalInCm*10+vitalInMm;
  //     //         =
  //     //         return height
  //     //     }else{
  //     //         headCircumference=vitalInCm*10+vitalInMm
  //     //         return otherApptInfo.headCircumference
  //     //     }
  //     // }

  // }

  handleTotalPaid() {
    let total = 0;
    this.state.selectedServices.forEach((item) => {
      const p = item.price * item.count;
      total += p;
    });
    this.setState({ totalAmount: total });
    return total;
  }

  handleTotalFees() {
    let total = 0;
    this.state.payment.forEach((item) => {
      console.log("itemPayment", item);
      total += item.amount;
    });
    this.setState({ feePaid: total });
    console.log("Total Amount", total);
    return total;
  }

  makeAppointmentHandler() {
    console.log(this.state.selectedSlot);
    console.log(this.state.otherApptInfo);
    // console.log(this.state.otherApptInfo);
    const isformValid = this.checkformforvalidation();

    if (!isformValid) {
      return null;
    }

    const nameSplitArr = this.state.selectedPatient.patient_fullnm.split(" ");
    let firstname = nameSplitArr[0];
    let lastname = "";
    if (nameSplitArr.length > 1) {
      nameSplitArr.splice(0, 1);
      lastname = nameSplitArr.join(" ");
    }
    const apptDateTime = new Date(
      this.state.selectedSlot - new Date().getTimezoneOffset() * 60000
    ).toISOString();
    const timeStamp = new Date(
      new Date() - new Date().getTimezoneOffset() * 60000
    ).toISOString();
    let appointmentData = null;
    if (this.state.isReshedule) {
      console.log("isReshedule", this.state.billing);
      console.log("isRes", this.state.payment);

      // debugger
      appointmentData = {
        appointment_datetime: apptDateTime,
        height:
          this.state.selectedPatient.speciality_category === "pediatrician" &&
          false
            ? this.state.otherApptInfo.heightInMm
            : this.state.otherApptInfo.height,
        weight: this.state.otherApptInfo.weight,
        l_bp: this.state.otherApptInfo.lowbp,
        h_bp: this.state.otherApptInfo.highbp,
        temperature: this.state.otherApptInfo.temprature,
        appointment_id: this.state.otherApptInfo.appointment_id,
        pulse: this.state.otherApptInfo.pulse,
        respiratory: this.state.otherApptInfo.respiratory,
        bmi: this.state.otherApptInfo.bmi,
        spotwo: this.state.otherApptInfo.spotwo,
        status: "Confirmed",
        revisit_flag: this.state.otherApptInfo.isRevisit,
        appt_patient_id: this.state.selectedPatient.patient_id.toString(),
        appt_doctor_id: this.props.user.selectedDoctor
          ? this.props.user.selectedDoctor.doctor_id
          : this.props.appointments.rescheduleAppt.appt_doctor_id,
        appt_address_id: this.props.appointments.rescheduleAppt.appt_address_id,
        updatedtm: new moment().format("YYYY-MM-DD HH:mm:ss"),
        patient_firstname: firstname,
        patient_lastname: lastname,
        patient_phone_number: this.state.selectedPatient.patient_phone_number,
        sex: this.state.selectedPatient.sex,
        patient_dob: this.state.selectedPatient.patient_dob
          ? this.state.selectedPatient.patient_dob
          : "1970-01-01",
        patient_age: this.state.selectedPatient.patient_age
          ? this.state.selectedPatient.patient_age
          : -1,
        patient_height:
          this.state.selectedDoctor.speciality_category === "pediatrician" &&
          false
            ? this.state.otherApptInfo.heightInMm
            : this.state.otherApptInfo.height,
        patient_weight: this.state.otherApptInfo.weight,
        purposeofvisit: this.state.otherApptInfo.isRevisit
          ? "Revisit"
          : "General",
        bloodgroup: this.state.selectedPatient.bloodgroup
          ? this.state.selectedPatient.bloodgroup
          : 0,
        last_updated: new moment().format("YYYY-MM-DD HH:mm:ss"),
        fees_paid: this.handleTotalFees(),
        total_amount: this.handleTotalPaid(),
        due_amount: 0,
        payment: this.props.appointments.rescheduleAppt.payment,
        billing: this.props.appointments.rescheduleAppt.billing,
        //billing: this.state.billing,
        city: this.state.selectedPatient.city,
        // "patient_address": {
        //     district: (this.state.selectedPatient.patient_address.district)?this.state.selectedPatient.patient_address.district:''
        // },
        appointment_type: this.state.otherApptInfo.appointment_type,
        // "head_circumference": this.state.otherApptInfo.headCircumference,
        // "tsf": this.state.otherApptInfo.tsf,
        // "ssf": this.state.otherApptInfo.ssf,
        // "muac": this.state.otherApptInfo.muac
      };
    } else {
      const billingObjects = this.state.selectedServices
        .filter((service) => service.count > 0)
        .map((service) => ({
          created_by: "reception",
          price: service.price,
          service_name: service.service_info.service_name,
          updated_by: "reception",
          units: service.count,
          updatedtm: service.updatedtm,
          service_id: service.service_info.service_id,
          createdtm: service.createdtm,
          tax: service.tax.replace("%", ""),
          service_type: service.service_type,
        }));

      let payableAmount = 0;
      billingObjects
        .filter((item) => item.price !== -1)
        .forEach((item) => {
          const p = item.price * item.units;
          payableAmount += p;
        });
      console.log("payableAmount ", payableAmount);

      const paymentObject = [
        {
          amount: payableAmount,
          payment_mode: "cash",
          is_service: true,
          updatedtm: localtimeiso(new Date()),
          collected_by: "reception",
          is_refund: "false",
          collectdtm: localtimeiso(new Date()),
        },
      ];

      console.log(
        this.state.selectedDoctor,
        this.state.otherApptInfo.heightInMm,
        this.state.otherApptInfo.height
      );
      appointmentData = {
        appointment_creation_datetime: new moment().format(
          "YYYY-MM-DDT HH:mm:ss"
        ),
        appt_patient_id: this.state.selectedPatient.patient_id
          ? this.state.selectedPatient.patient_id.toString()
          : null,
        appt_doctor_id: this.state.docSelected,
        appt_address_id: this.state.addressId,
        mode_of_appointment: "walkin",
        appointment_datetime: apptDateTime,
        status: "Confirmed",
        actual_appointment_time: apptDateTime,
        revisit_flag: this.state.otherApptInfo.isRevisit,
        purposeofvisit: this.state.otherApptInfo.isRevisit
          ? "Revisit"
          : "General",
        height:
          this.state.selectedDoctor.speciality_category === "pediatrician" &&
          false
            ? this.state.otherApptInfo.heightInMm
            : this.state.otherApptInfo.height,
        weight: this.state.otherApptInfo.weight,
        is_delivered: false,
        //"pharma_id": 0,
        l_bp: this.state.otherApptInfo.lowbp,
        h_bp: this.state.otherApptInfo.highbp,
        temperature: this.state.otherApptInfo.temprature,
        pulse: this.state.otherApptInfo.pulse,
        respiratory: this.state.otherApptInfo.respiratory,
        bmi: this.state.otherApptInfo.bmi,
        spotwo: this.state.otherApptInfo.spotwo,
        revisit_days: -1,
        appointment_id: null,
        confirmedappt_flag: false,
        checkout_flag: false,
        updatedtm: new moment().format("YYYY-MM-DD HH:mm:ss"),
        updated_by: "website",
        islocalappt: "true",
        islocalpatient: "false",
        //"fees_paid": "0",
        patient_id: this.state.selectedPatient.patient_id,
        patient_firstname: firstname,
        patient_lastname: lastname,
        patient_phone_number: this.state.selectedPatient.patient_phone_number,
        sex: this.state.selectedPatient.sex,
        patient_dob: this.state.selectedPatient.patient_dob
          ? this.state.selectedPatient.patient_dob
          : "1970-01-01",
        patient_age: this.state.selectedPatient.patient_age
          ? this.state.selectedPatient.patient_age
          : -1,
        patient_height:
          this.state.selectedDoctor.speciality_category === "pediatrician" &&
          false
            ? this.state.otherApptInfo.heightInMm
            : this.state.otherApptInfo.height,
        patient_weight: this.state.otherApptInfo.weight,
        bloodgroup: this.state.selectedPatient.bloodgroup
          ? this.state.selectedPatient.bloodgroup
          : 0,
        last_updated: new moment().format("YYYY-MM-DD HH:mm:ss"),
        fees_paid: payableAmount, //this.handleTotalFees(),
        total_amount: payableAmount, //this.handleTotalPaid(),
        due_amount: 0,
        payment: paymentObject, //this.state.payment,
        billing: billingObjects, //this.state.billing,
        patient_system_registration_date: this.state.selectedPatient
          .patient_system_registration_date
          ? this.state.selectedPatient.patient_system_registration_date
          : new Date(),
        // "patient_address": {
        //     district: (this.state.selectedPatient.patient_address.district)?this.state.selectedPatient.patient_address.district:''
        // },
        city: this.state.selectedPatient.city,
        appointment_type: this.state.otherApptInfo.appointment_type,
        // "head_circumference": this.state.otherApptInfo.headCircumference,
        // "tsf": this.state.otherApptInfo.tsf,
        // "ssf": this.state.otherApptInfo.ssf,
        // "muac": this.state.otherApptInfo.muac
      };
    }
    console.log("Manshi", appointmentData);
    debugger;
    axios.post("/postnewappointment_reception_web", appointmentData).then(
      (response) => {
        console.log(response);
        // debugger;
        this.props.storeUpcomingAppointments([]);
        if (!response.data.hasOwnProperty("severity")) {
          // this.props.setApptConfirmation((!this.state.isReshedule) ? 'confirmed': (this.props.appointments.apptType!=='edit') ? 'reschedule' : 'edit');
          let appointmentDetail = {
            patientName:
              response.data.patient_firstname +
              " " +
              response.data.patient_lastname,
            slotNumber: response.data.slot_number,
            doctor_id: response.data.appt_doctor_id,
            // city: (response.data && response.data.patient_address && response.data.patient_address.district)?response.data.patient_address.district:'',
            city: response.data.city,
            appointmentType: response.data.appointment_type,
            slotNumber: response.data.slot_number,
          };
          console.log(appointmentDetail);
          console.log(this.props);
          let docList = this.props.printingDetails.docPrintList.filter(
            (doctor) => {
              return doctor.doctor_id === appointmentDetail.doctor_id;
            }
          );
          console.log(docList);
          // debugger;
          if (docList.length > 0 && !this.state.isReshedule) {
            this.props.storePrintingDetails(appointmentDetail);
            this.props.history.push("/printingpage");
          } else {
            this.props.history.push("/");
          }
          this.props.setApptConfirmation(
            !this.state.isReshedule
              ? "confirmed"
              : this.props.appointments.apptType !== "edit"
              ? "reschedule"
              : "edit"
          );
        } else if (response.data["severity"] === "Appointment Already Exists") {
          const patDetails = { ...this.state.selectedPatient };
          const otherData = { ...this.state.otherApptInfo };
          patDetails.patient_id = response.data.appt_patient_id;
          otherData.appointment_id = response.data.appointment_id;
          this.setState({
            existedApptTime:
              Date.parse(response.data.appointment_datetime) +
              new Date().getTimezoneOffset() * 60 * 1000,
            otherApptInfo: otherData,
            selectedPatient: patDetails,
            isReshedule: true,
            reschModalActive: true,
            selectedDoctor: response.data.appt_doctor_id,
          });
        } else {
          this.props.setApptConfirmation("error");
          this.props.history.push("/");
        }
      },
      (error) => {
        this.props.setApptConfirmation("error");
        this.props.history.push("/");
      }
    );
  }

  selectSlotHandler(slotTime) {
    this.setState({
      selectedSlot: slotTime,
      view: this.state.isReshedule ? 3 : 2,
    });
  }

  selectPatientHandler(patDetails, isSkip) {
    let otherApptInfo = null;
    let value = null;

    if (new Date(patDetails.patient_dob).getTime() == 0) {
      patDetails.patient_dob = "";
    } else {
      this.setState({ isDobUpdate: false });
    }

    if (isSkip) {
      otherApptInfo = {
        isRevisit: false,
        height: null,
        heightCm: null,
        heightMm: null,
        heightFeet: null,
        heightInch: null,
        weightKg: null,
        weightGm: null,
        weight: null,
        temprature: null,
        lowbp: null,
        highbp: null,
        respiratory: null,
        spotwo: null,
        bmi: null,
        pulse: null,
        headCircumference: null,
        headCircumferenceCm: null,
        headCircumferenceMm: null,
        appointment_type: "Normal",
      };
    } else {
      // debugger
      console.log(patDetails);
      otherApptInfo = { ...this.state.otherApptInfo };
      console.log(otherApptInfo);
      if (
        patDetails.patient_height !== null &&
        patDetails.patient_height >= 0
      ) {
        value = patDetails.patient_height;
        console.log(convertToCmMm(value).cm);
        console.log(convertToCmMm(value).mm);
        otherApptInfo.height = value;
        otherApptInfo.heightCm = convertToCmMm(value).cm;
        otherApptInfo.heightMm = convertToCmMm(value).mm;
        otherApptInfo.heightFeet = cmtofeetinches(value).feet;
        otherApptInfo.heightInch = cmtofeetinches(value).inch;
      }
      if (
        patDetails.patient_weight !== null &&
        patDetails.patient_weight !== undefined
      ) {
        value = patDetails.patient_weight;
        otherApptInfo.weight = value;
        otherApptInfo.weightGm = value % 1000;
        otherApptInfo.weightKg = parseInt(value / 1000);
      }
      if (otherApptInfo.height && otherApptInfo.weight) {
        // debugger
        // otherApptInfo.height = convertIntoDecimal(otherApptInfo.heightCm,otherApptInfo.heightMm)
        // otherApptInfo.weight = convertIntoDecimal(otherApptInfo.weightKg,otherApptInfo.weightGm)
        console.log(otherApptInfo.height, otherApptInfo.weight);
        otherApptInfo.bmi = this.calculateBMI(
          otherApptInfo.weight,
          otherApptInfo.height
        );
        otherApptInfo.appointment_type = "Normal";
        console.log(otherApptInfo.bmi);
      }
    }

    //this.dropdownApptSelectHandler('weightGm', patDetails.patient_weight);
    this.setState({
      selectedPatient: patDetails,
      otherApptInfo: otherApptInfo,
      view: 3,
      isNameValid: true,
      isNumberValid: true,
    });
  }

  nameChangeHandler(value) {
    console.log(value);
    // debugger;
    const updatedData = { ...this.state.selectedPatient };
    console.log(updatedData);
    console.log(value.charAt(0));
    // const isValid = checkforname(value) && value.charAt(0)!==' ' && !(value.indexOf('  ') > -1);
    const isValid = checkforname(value);
    console.log(isValid);
    // debugger
    if (isValid) {
      // debugger
      updatedData.patient_fullnm = value;
      this.setState({ selectedPatient: updatedData });
    }
    console.log(updatedData);
    this.setState({ isNameValid: isValid });
  }

  cityChangeHandler(value) {
    // debugger;
    const updatedData = { ...this.state.selectedPatient };
    let patient_address = {
      district: "",
    };
    // console.log(updatedData);
    // const isValid = checkforcity(value) && value.trim().charAt(0)!==' ' && !(value.indexOf('  ') > -1);
    const isValid = checkforcity(value);
    console.log(isValid);
    if (isValid) {
      // patient_address.district=value
      // updatedData.patient_address = patient_address
      console.log(updatedData);
      updatedData.city = value;
      this.setState({ selectedPatient: updatedData });
    }
    this.setState({ isCityValid: isValid });
  }

  numberChangeHandler(value) {
    const updatedData = { ...this.state.selectedPatient };
    const isValid =
      value == "" || (checkfornumber(value) && parseInt(value.charAt(0)) > 5);
    if (isValid) {
      updatedData.patient_phone_number = value;
    } else {
      updatedData.patient_phone_number = value.substr(0, value.length - 1);
    }
    this.setState({ selectedPatient: updatedData });
    this.setState({ isNumberValid: isValid });
  }

  // billingData = (object) => {
  //   this.setState({ billing: object });
  // };

  calculateBMI(weight, height) {
    // debugger
    let bmi = null;
    console.log(weight);
    console.log(height);
    if (weight && height) {
      bmi = ((weight / (height * height)) * 10).toFixed(2);
      return bmi;
    }
    return bmi;
  }

  // handleBMIValue(weight, height){
  //     debugger;
  //     let bmi = null;
  //     console.log('Handle BMI');
  //     console.log(weight);
  //     console.log(height);
  //     if(weight && height){
  //         bmi =  this.calculateBMI(weight, height);
  //         if(bmi){
  //             return bmi;
  //         }
  //     }
  //     console.log(bmi);
  //     return bmi
  // }

  dropdownApptSelectHandler(type, value) {
    // debugger;
    console.log(type, value);
    // debugger
    let selectedAppt = { ...this.state.otherApptInfo };
    console.log("hey", type, value, selectedAppt);

    // debugger;
    switch (type) {
      case "purpose": {
        selectedAppt.isRevisit = value;
        break;
      }
      case "cmheight": {
        selectedAppt.height = convertVitalInMm(value, 0);
        selectedAppt.heightCm = value;
        selectedAppt.heightInMm = convertVitalInMm(value, 0);
        selectedAppt.heightFeet = cmtofeetinches(value).feet;
        selectedAppt.heightInch = cmtofeetinches(value).inch;
        console.log(
          selectedAppt.heightInMm,
          selectedAppt.heightFeet,
          selectedAppt.heightInch
        );
        selectedAppt.bmi = this.calculateBMI(
          selectedAppt.weight,
          selectedAppt.height / 10
        );
        break;
      }
      case "feetheight": {
        selectedAppt.heightFeet = value;
        if (selectedAppt.heightInch == null) {
          selectedAppt.heightInch = 0;
        }
        selectedAppt.height = Math.ceil(
          selectedAppt.heightFeet * 30 + selectedAppt.heightInch * 2.54
        );
        selectedAppt.heightCm = selectedAppt.height;
        selectedAppt.bmi = this.calculateBMI(
          selectedAppt.weight,
          selectedAppt.height
        );
        break;
      }
      case "inchheight": {
        selectedAppt.heightInch = value;
        selectedAppt.heightFeet =
          selectedAppt.heightFeet != null ? selectedAppt.heightFeet : 0;
        selectedAppt.height = Math.ceil(
          selectedAppt.heightFeet * 30 + selectedAppt.heightInch * 2.54
        );
        selectedAppt.heightCm = selectedAppt.height;
        selectedAppt.bmi = this.calculateBMI(
          selectedAppt.weight,
          selectedAppt.height
        );
        break;
      }
      case "temprature": {
        selectedAppt.temprature = value;
        break;
      }
      case "weightKg": {
        // debugger
        selectedAppt.weightKg = value;
        if (selectedAppt.weightGm === null) {
          selectedAppt.weightGm = 0;
        }
        selectedAppt.weight = value * 1000 + selectedAppt.weightGm;
        console.log(
          convertVitalInMm(selectedAppt.heightCm, selectedAppt.heightMm)
        );
        selectedAppt.bmi = this.calculateBMI(
          selectedAppt.weight,
          selectedAppt.height / 10
        );
        break;
      }
      case "weightGm": {
        // debugger;
        if (value > 999) {
          selectedAppt.weightKg = parseInt(value / 1000);
          selectedAppt.weightGm = value % 1000;
        } else {
          selectedAppt.weightGm = value >= 0 ? value : null;
          selectedAppt.weightKg =
            selectedAppt.weightKg != null ? selectedAppt.weightKg : 0;
        }
        selectedAppt.weight = selectedAppt.weightKg * 1000 + value;
        selectedAppt.bmi = this.calculateBMI(
          selectedAppt.weight,
          selectedAppt.height / 10
        );
        break;
      }
      case "highbp": {
        selectedAppt.highbp = value;
        break;
      }
      case "lowbp": {
        selectedAppt.lowbp = value;
        break;
      }
      case "pulseMin": {
        selectedAppt.pulse = value;
        break;
      }
      case "respiratory": {
        selectedAppt.respiratory = value;
        break;
      }
      case "spotwo": {
        selectedAppt.spotwo = value;
        break;
      }
      case "appointmentType": {
        selectedAppt.appointment_type = value;
        break;
      }
      case "cmMmHeight": {
        selectedAppt.heightCm = value;
        if (selectedAppt.heightMm == null) {
          selectedAppt.heightMm = 0;
        }
        selectedAppt.height = convertVitalInMm(
          selectedAppt.heightCm,
          selectedAppt.heightMm
        );
        console.log(selectedAppt.height);
        // debugger;
        selectedAppt.heightInMm = convertVitalInMm(
          selectedAppt.heightCm,
          selectedAppt.heightMm
        );
        selectedAppt.bmi = this.calculateBMI(
          selectedAppt.weight,
          selectedAppt.height / 10
        );
        break;
      }
      case "mmHeight": {
        selectedAppt.heightMm = value;
        console.log("mm height", selectedAppt.heightMm);
        selectedAppt.height = convertVitalInMm(
          selectedAppt.heightCm,
          selectedAppt.heightMm
        );
        // selectedAppt.heightInMm = convertVitalInMm(selectedAppt.heightCm, selectedAppt.heightMm)
        selectedAppt.bmi = this.calculateBMI(
          selectedAppt.weight,
          selectedAppt.height / 10
        );
        break;
      }
      case "headCircumferenceCm": {
        // debugger;
        selectedAppt.headCircumferenceCm = value;
        if (selectedAppt.headCircumferenceMm == null) {
          selectedAppt.headCircumferenceMm = 0;
        }
        selectedAppt.headCircumference = convertVitalInMm(
          selectedAppt.headCircumferenceCm,
          selectedAppt.headCircumferenceMm
        );
        console.log(selectedAppt.headCircumference);
        break;
      }

      case "headCircumferenceMm": {
        selectedAppt.headCircumferenceMm = value;
        selectedAppt.headCircumference = convertVitalInMm(
          selectedAppt.headCircumferenceCm,
          selectedAppt.headCircumferenceMm
        );
        console.log(selectedAppt.headCircumference);
        break;
      }
      case "tsf": {
        selectedAppt.tsf = value;
        break;
      }
      case "muac": {
        selectedAppt.muac = value;
        break;
      }
      case "ssf": {
        selectedAppt.ssf = value;
        break;
      }
    }
    console.log("selectAppt", selectedAppt);
    // debugger;
    this.setState({ otherApptInfo: selectedAppt });
  }

  checkformforvalidation() {
    console.log(this.state.selectedPatient);
    // debugger
    //Check for name length
    //Check for phone number length
    let isformvalid = true;
    if (
      this.state.selectedPatient &&
      this.state.selectedPatient.patient_fullnm.length < 3
    ) {
      // debugger;
      this.setState({ isNameValid: false, isCityValid: false });
      isformvalid = false;
    }

    if (this.state.selectedPatient.city) {
      if (this.state.selectedPatient.city.length < 3) {
        this.setState({
          isCityValid: false,
        });
        isformvalid = false;
      }
    }

    if (
      this.state.selectedPatient.patient_phone_number &&
      (this.state.selectedPatient.patient_phone_number.length < 10 ||
        parseInt(this.state.selectedPatient.patient_phone_number.charAt(0)) < 6)
    ) {
      isformvalid = false;
      this.setState({ isNumberValid: false });
    }
    return isformvalid;
  }

  dropdownPatSelectHandler(type, value) {
    debugger;
    let selectedPatient = { ...this.state.selectedPatient };
    switch (type) {
      case "age": {
        selectedPatient.patient_age = value;
        // selectedPatient.patient_dob = null;
        break;
      }
      case "gender": {
        selectedPatient.sex = value;
        break;
      }
      case "bg": {
        selectedPatient.bloodgroup = value;
        break;
      }
    }
    this.setState({ selectedPatient: selectedPatient });
  }

  backHandler() {
    console.log(this.state.view);
    // debugger;
    if (
      this.state.view == 0 ||
      (this.state.view == 1 &&
        (this.props.user.selectedDoctor || this.state.isReshedule) &&
        this.state.isReshedule)
    ) {
      window.location.href = "/";
    } else if (
      (this.state.isReshedule && this.props.appointments.apptType === "edit") ||
      (this.props.appointments.isQuickAdd && this.state.view == 2)
    ) {
      window.location.href = "/";
    } else {
      if (this.state.isReshedule && this.state.view == 3) {
        this.setState({ view: 1 });
      } else {
        const view = this.state.view - 1;
        this.setState({ view: view });
      }
    }
    if (!this.state.isReshedule) {
      this.setState({ selectedServices: [] });
    }
  }

  render() {
    console.log("Remove", this.state.billing);
    console.log("Selected", this.state.selectedServices);
    let createApptView = null;
    console.log(this.state.view);
    console.log("vv", this.props);
    // debugger
    console.log("vishesh22", this.props.user.userData);
    switch (this.state.view) {
      case 0: {
        createApptView = (
          <SelectDoctor
            goBack={() => this.backHandler()}
            doctors={this.props.user.userData.doctors}
            select={(doctor) => this.selectDoctorHandler(doctor)}
            selectedServices={this.state.selectedServices}
          />
        );

        break;
      }
      case 1: {
        console.log("vishesh7: ", this.state);
        createApptView = (
          <SelectSlot
            goBack={() => this.backHandler()}
            selectedDate={this.state.selectedDate}
            docId={
              this.state.isReshedule
                ? this.props.appointments.rescheduleAppt.appt_doctor_id
                : this.state.docSelected
            }
            addId={this.state.addressId}
            selectDate={(date) => this.setState({ selectedDate: date })}
            selectSlot={(slotTime) => this.selectSlotHandler(slotTime)}
          />
        );
        break;
      }
      case 2: {
        console.log("vishesh18", this.state);
        createApptView = (
          <SelectPatient
            goBack={() => this.backHandler()}
            doctors={this.state.selectedDoctor}
            docSelected={this.state.docSelected}
            selectedServices={this.state.selectedServices}
            addressId={this.state.addressId}
            patientSelect={(patientDetails, isSkip) =>
              this.selectPatientHandler(patientDetails, isSkip)
            }
          />
        );
        break;
      }
      case 3: {
        console.log("vishesh19", this.state);
        console.log(this.state.selectedPatient);
        console.log("hlo", this.state.otherApptInfo);
        console.log(this.props.user);
        console.log(this.state.previousPayment);
        console.log("this payment props", this.state.payment);
        createApptView = (
          <PatientDetails
            dataBilling={this.callbackBilling}
            dataPayment={this.callBackPayment}
            sendSelectedData={this.callBackSelectService}
            previousPayment={this.state.previousPayment}
            sendSelectedService={this.state.selectedServices}
            newBillingArray={this.state.billing}
            newPayment={this.state.payment}
            goBack={() => {
              const otherApptInfo =
                !this.props.appointments.apptType === "reschedule" &&
                this.props.appointments.apptType === "edit"
                  ? {
                      isRevisit: false,
                      height: null,
                      heightCm: null,
                      heightMm: null,
                      heightFeet: null,
                      heightInch: null,
                      weightKg: null,
                      weightGm: null,
                      weight: null,
                      temprature: null,
                      lowbp: null,
                      highbp: null,
                      pulse: null,
                      respiratory: null,
                      spotwo: null,
                      bmi: null,
                      headCircumference: null,
                      mauc: null,
                      ssf: null,
                    }
                  : this.state.otherApptInfo;
              this.setState({ otherApptInfo: otherApptInfo });
              this.backHandler();
            }}
            isResAppt={this.state.isReshedule}
            selectedPatient={this.state.selectedPatient}
            selectedDoctor={this.state.selectedDoctor}
            paymentData={this.state.payment}
            nameChange={(value) => this.nameChangeHandler(value)}
            cityChange={(value) => this.cityChangeHandler(value)}
            numberChange={(value) => this.numberChangeHandler(value)}
            otherApptinfo={this.state.otherApptInfo}
            isNameValid={this.state.isNameValid}
            isCityValid={this.state.isCityValid}
            setFieldTrue={(fieldName) => this.setFieldTrue(fieldName)}
            changeTab={(view) => this.setState({ view: view })}
            isNumberValid={this.state.isNumberValid}
            selectFromDropdown={(type, value) =>
              this.dropdownPatSelectHandler(type, value)
            }
            selectFromDropdownAppt={(type, value) =>
              this.dropdownApptSelectHandler(type, value)
            }
            dobSelect={(dobValue) => {
              //set age based on dob
              //set dob state
              // console.log(dobValue);
              const patient = {
                ...this.state.selectedPatient,
                patient_dob: new moment(dobValue).format("YYYY-MM-DD"),
                patient_age: calculateage(dobValue),
              };
              console.log("PATIENT AGE AND DOB", patient);
              this.setState({ selectedPatient: patient });
            }}
            isDobUpdate={this.state.isDobUpdate}
            updatepayment={(fee, total, due, payment, billing) => {
              // console.log(fee, total, due, payment, billing);
              console.log("Billing", billing);
              console.log(
                "paymentdatavishesh:",
                fee,
                total,
                due,
                payment,
                billing
              );
              // debugger;
              this.setState({
                feePaid: fee,
                totalAmount: total,
                due: due,
                payment: payment,
                billing: billing,
              });
            }}
          />
        );
        break;
      }
      // case 4 : {
      //     createApptView = <PatientVitals changeTab={(view)=>this.setState({view:view})}  />
      //     break;
      // }
      // case 5 : {
      //     createApptView = <QuickPayment changeTab={(view)=>this.setState({view:view})}  />
      //     break;
      // }
    }
    return (
      <div className="createAppointment">
        <div className="apptCategory">{createApptView}</div>

        <AppointmentSummary
          makeAppointment={() => this.makeAppointmentHandler()}
          selectedSlot={this.state.selectedSlot}
          doctor={this.state.selectedDoctor}
          isHospital={
            this.props.user.userData && this.props.user.userData.isHospital
          }
          selectedPatient={this.state.selectedPatient}
          apptInProgress={this.state.progressAppt}
          view={this.state.view}
          apptType={this.props.appointments.apptType}
          isValid={this.state.isValid}
        />
        <Modal
          className="resModal"
          show={this.state.reschModalActive}
          onHide={() => {
            this.props.history.push("/");
          }}
          animation={true}
        >
          <Modal.Body>
            <div className="rescModal">
              <div className="modHead">Appointment Exists</div>
              <p className="modContent">
                An appointment already exists at{" "}
                <span className="modSlotTime">
                  {new moment(this.state.existedApptTime).format(
                    "DD/MM/YY hh:mm a"
                  )}
                </span>
                <br />
                Do you want to reschedule this appointment to{" "}
                <span className="modSlotTime">
                  {new moment(this.state.selectedSlot).format(
                    "DD/MM/YY hh:mm a"
                  )}
                </span>{" "}
                ?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="modFooter">
            <div>
              <span
                className="modLink"
                onClick={() => {
                  this.setState({ reschModalActive: false });
                  this.props.history.push("/");
                }}
              >
                NO, GO BACK TO DASHBOARD
              </span>
              <span
                className="modButton"
                onClick={() => this.makeAppointmentHandler()}
              >
                YES, RESCHEDULE IT
              </span>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    date: state.date,
    appointments: state.appointmentData,
    user: state.user,
    printingDetails: state.printDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDateChange: (date) => dispatch({ type: "DATE_CHANGE", value: date }),
    storeUpcomingAppointments: (appts) =>
      dispatch({ type: "STORE_UPCOMING_APPTS", appointments: appts }),
    storePatDetails: (details) =>
      dispatch({ type: "STORE_PATIENT_DATA", value: details }),
    setApptConfirmation: (isConfirmed) =>
      dispatch({ type: "SET_APPT_CONFIRMATION", isConfirmed: isConfirmed }),
    storePrintingDetails: (apptDetails) =>
      dispatch({ type: "STORE_PRINTING_DETAILS", patDetails: apptDetails }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointment);
