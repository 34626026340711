import React from "react";

const SubmitMessage = () => {
  return (
    <div className="deleteMessageContainer">
      <h1>We have taken your request...</h1>
      <br />
      <h3>We will contact you shortly.</h3>
      <h5>Contact Us: +911204558119</h5>
    </div>
  );
};
export default SubmitMessage;
