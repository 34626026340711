import React, { useEffect, useState } from "react";
import "./DeleteProfile.css";
import axios from "../../axios-order";

const DeleteRequestForm = ({ setMessage }) => {

  const [userDetails, setUserDetails] = useState({
    phoneNumber: "",
    name: "",
    reason: "",
  });

  const validateDataAndPostToServer = (name, number) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!nameRegex.test(name)) {
      return alert("Please check name");
    }
    if (!/^[0-9]{10}/.test(number)) {
      return alert("Please check number");
    } else {
      submitRequest();
    }
  };

  const dataObj = {
    phoneNumber: userDetails.phoneNumber,
    name: userDetails.name,
    message: userDetails.reason
  };

  const submitRequest = () => {
    setMessage(1);
    axios.post("/postUserDeleteQuery", dataObj)
      .then(
        (response) => {
          setMessage(2);
        },
        (error) => {
          //Error Instructions goes here
          alert("Something went wrong. Try Again.");
          setMessage(0);
        }
      );
  };

  return (
    <div className="deleteContainer">
      <div className="imageArea">
        <img src="https://res.cloudinary.com/daedgnllx/image/upload/v1600763592/athancare_website_assets/home/foreground_images/layer-40_clutb7.png" />
      </div>
      <div className="inputArea">
        <div className="deletHeading">DELETE YOUR PROFILE</div>
        <form action="">
          <div className="input-field-login">
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              maxLength="10"
              onChange={(e) => {
                setUserDetails({ ...userDetails, phoneNumber: e.target.value });
              }}
              required
            />
            <label>Phone Number</label>
          </div>
          <div className="input-field-login">
            <input
              type="text"
              name="name"
              maxLength="50"
              onChange={(e) => {
                setUserDetails({ ...userDetails, name: e.target.value });
              }}
              required
            />
            <label>Name</label>
          </div>
          <div className="input-field-login">
            <input
              type="text"
              name="reason"
              maxLength="150"
              onChange={(e) => {
                setUserDetails({ ...userDetails, reason: e.target.value });
              }}
            />
            <label>Reason</label>
          </div>
          <button
            type="submit"
            className="loginButton"
            onClick={() => {
              validateDataAndPostToServer(userDetails.name, userDetails.phoneNumber);
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default DeleteRequestForm;
