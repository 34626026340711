import React, { useState } from "react";
import "./DayView.css";
// import apptCancelIcon from '../../assets/images/icons/cancle.png';
// import dotMenu from '../../assets/images/icons/dot_menu.png';
// import rescheduleIcon from '../../assets/images/icons/reschedule.png';
import PatientAppointment from "./PatientAppointment/PatientAppointment";
// import {Link} from 'react-router-dom';
import moment from "moment";
import Spinner from "../../assets/images/spinner.gif";
import { calculateage } from "../../assets/js/calculate_details";
import { reverse } from "dns";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import upIcon from "../../assets/images/icons/Up.svg";
import downIcon from "../../assets/images/icons/Down.svg";

function DayView(props) {
  console.log(props);
  console.log("props doc", props.docPrintList);
  let docPrintList = [];
  if (props.docPrintList) {
    docPrintList = props.docPrintList;
  }

  function getbillingamount(billingArray) {
    if (billingArray === undefined) {
      return null;
    }
    console.log(billingArray);
    const medBill = billingArray.filter(
      (item) => item.created_by === "reception"
    );
    return medBill.length > 0 ? medBill[0].price : 0;
  }
  console.log("Dignosed Appts", props.appointments);
  let recentAppts = [];
  let recentApptTemplate = [];
  let apptView = null;
  console.log(recentAppts);

  // if(props.isLoading){dayview
  //     apptView = (<div className="dayViewLoader"></div>)
  // } else {
  // console.log(props.appointments && Object.keys(props.appointments).length);
  apptView =
    props.appointments && Object.keys(props.appointments).length ? (
      Object.keys(props.appointments).map((time, index) => {
        // console.log(Object.keys(props.appointments).length);
        let apptTemplate = props.appointments[time].map(
          (appointment, index) => {
            console.log(
              "Appointment",
              appointment,
              new moment.utc(appointment.updatedtm).format("hh:mm a")
            );
            recentAppts.push(appointment);
            const appointmentDetail = {
              patientName: appointment.patient_fullnm,
              patientAge: calculateage(
                appointment.patient_dob,
                appointment.patient_age
              ),
              apptTime: new moment(
                appointment.appointment_datetime.substr(
                  0,
                  appointment.appointment_datetime.length - 1
                )
              ).format("hh:mm a"),
              phoneNumber: appointment.patient_phone_number,
              patientSex: appointment.sex,
              updatedTime: new moment(appointment.updatedtm)
                .utc()
                .format("hh:mm a"),
              prescription:
                appointment.prescriptions &&
                appointment.prescriptions[0].image_data.final.length > 0
                  ? appointment.prescriptions
                  : null,
              slotNumber: appointment.slot_number,
              isPrintActive:
                docPrintList.filter((doc) => {
                  return appointment.appt_doctor_id === doc.doctor_id;
                }).length > 0,
              doctor_id: appointment.appt_doctor_id,
              // (appointment && appointment.patient_address && appointment.patient_address.district)?appointment.patient_address.district:''
              city: appointment.city,
              appointmentType: appointment.appointment_type,
              slotNumber: appointment.slot_number,
            };
            console.log(appointmentDetail);
            console.log(
              "Appointment Details & date time",
              appointmentDetail.updatedTime
            );
            console.log("props.selectedCategory", appointment.sub_total_rec);
            // console.log(props.isLoadingAppt);
            return props.isLoadingAppt ? (
              <div>Loading...</div>
            ) : (
              <PatientAppointment
                key={appointment.appointment_id}
                colorCode={props.docColorCodes[appointment.appt_doctor_id]}
                name={appointmentDetail.patientName}
                age={appointmentDetail.patientAge}
                gender={appointmentDetail.patientSex}
                phoneNumber={appointmentDetail.phoneNumber}
                apptTime={appointmentDetail.apptTime}
                updatedTime={appointmentDetail.updatedTime}
                cancelAppointment={(status) =>
                  props.cancelAppt(
                    appointment.appointment_id,
                    status,
                    appointment
                  )
                }
                updatepayment={() => props.setpayment(appointment)}
                reschedule={(type) => props.rescheduleAppt(appointment, type)}
                openPrintPage={() => props.openPrintPage(appointmentDetail)}
                isPrintActive={appointmentDetail.isPrintActive}
                amountPaid={
                  // appointment.sub_total_rec ?
                  props.selectedCategory === 1
                    ? appointment.sub_total_rec +
                      "/" +
                      appointment.sub_total_doc
                    : appointment.sub_total_rec + appointment.sub_total_doc
                  // : "0"
                }
                category={props.selectedCategory}
                scan={() => props.scan(appointment)}
                isLoadingAppt={props.isLoadingAppt}
                showPrescription={() => props.showPrescription(appointment)}
                isPrescriptionExist={
                  appointment.prescriptions &&
                  appointment.prescriptions[0].image_data.final.length > 0
                }
                appointmentType={appointmentDetail.appointmentType}
                slotNumber={appointmentDetail.slotNumber}
              />
            );
          }
        );
        let apptByUpdatedTime = recentAppts.sort(
          (appt1, appt2) =>
            new Date(appt2.updatedtm).getTime() -
            new Date(appt1.updatedtm).getTime()
        );
        console.log(apptByUpdatedTime);
        let lastThreeAppts = apptByUpdatedTime.filter((appt, index) => {
          if (index < 3) {
            return appt;
          }
        });

        recentApptTemplate = lastThreeAppts
          ? lastThreeAppts.map((appointment, index) => {
              console.log(
                "Recent Appointment",
                appointment,
                new moment.utc(appointment.updatedtm).format("hh:mm a")
              );
              const appointmentDetail = {
                patientName: appointment.patient_fullnm,
                patientAge: calculateage(
                  appointment.patient_dob,
                  appointment.patient_age
                ),
                apptTime: new moment(
                  appointment.appointment_datetime.substr(
                    0,
                    appointment.appointment_datetime.length - 1
                  )
                ).format("hh:mm a"),
                phoneNumber: appointment.patient_phone_number,
                patientSex: appointment.sex,
                updatedTime: new moment(appointment.updatedtm)
                  .utc()
                  .format("hh:mm a"),
                prescription:
                  appointment.prescriptions &&
                  appointment.prescriptions[0].image_data.final.length > 0
                    ? appointment.prescriptions
                    : null,
                slotNumber: appointment.slot_number,
                isPrintActive:
                  docPrintList.filter((doc) => {
                    return appointment.appt_doctor_id === doc.doctor_id;
                  }).length > 0,
                doctor_id: appointment.appt_doctor_id,
                city: appointment.city,
                // city: (appointment && appointment.patient_address && appointment.patient_address.district)?appointment.patient_address.district:'',
                appointmentType: appointment.appointment_type,
                slotNumber: appointment.slot_number,
              };
              console.log(appointmentDetail);
              console.log(
                "Appointment Details & date time",
                appointmentDetail.updatedTime
              );
              console.log("rest");
              return props.isLoadingAppt ? (
                <div>Loading...</div>
              ) : (
                <PatientAppointment
                  key={"rec_" + appointment.appointment_id}
                  colorCode={props.docColorCodes[appointment.appt_doctor_id]}
                  name={appointmentDetail.patientName}
                  age={appointmentDetail.patientAge}
                  gender={appointmentDetail.patientSex}
                  phoneNumber={appointmentDetail.phoneNumber}
                  apptTime={appointmentDetail.apptTime}
                  updatedTime={appointmentDetail.updatedTime}
                  cancelAppointment={(status) =>
                    props.cancelAppt(
                      appointment.appointment_id,
                      status,
                      appointment
                    )
                  }
                  updatepayment={() => props.setpayment(appointment)}
                  reschedule={(type) => props.rescheduleAppt(appointment, type)}
                  openPrintPage={() => props.openPrintPage(appointmentDetail)}
                  isPrintActive={appointmentDetail.isPrintActive}
                  amountPaid={appointment.sub_total_rec}
                  category={props.selectedCategory}
                  scan={() => props.scan(appointment)}
                  isLoadingAppt={props.isLoadingAppt}
                  showPrescription={() => props.showPrescription(appointment)}
                  isPrescriptionExist={
                    appointment.prescriptions &&
                    appointment.prescriptions[0].image_data.final.length > 0
                  }
                  appointmentType={appointmentDetail.appointmentType}
                  slotNumber={appointmentDetail.slotNumber}
                />
              );
            })
          : null;
        console.log("hlo", recentAppts);
        console.log(
          recentAppts.sort(
            (appt1, appt2) =>
              new Date(appt2.updatedtm).getTime() -
              new Date(appt1.updatedtm).getTime()
          )
        );
        console.log("Time is ", time);
        return (
          <div className="apptHour" key={"key_" + time + index.toString()}>
            <div className="hour">
              {new moment(new Date().setHours(time, 0, 0, 0)).format("hh:mm a")}
            </div>
            <div className="apptList">
              <div className="timeline hide">
                <div className="timelineMarker left"></div>
                <div className="hrline"></div>
                <div className="clear"></div>
              </div>
              {apptTemplate}
            </div>
          </div>
        );
      })
    ) : (
      <div className="noData">No appointments available</div>
    );

  const emptyClass = {
    width: localStorage.getItem("payment_visibility") === "true" ? "18%" : "2%",
  };

  const [collapse, setCollapse] = useState(true);

  const [status, setStatus] = useState("Closed");

  const onEntering = () => setStatus("Opening...");

  const onEntered = () => setStatus("Opened");

  const onExiting = () => setStatus("Closing...");

  const onExited = () => setStatus("Closed");

  const toggle = () => setCollapse(!collapse);

  return (
    <div>
      {/* <div className="appointments-container">
                <div className="appointments-status">All Appointments</div>
                <div className="appointments-search">
                  
                </div>
                <div className="add-appointment">
                    <Link to="/createappointment" exact className="add-appointment-button">
                        <span>Add Appointment</span>
                    </Link>
                </div>
            </div> */}
      <div className="apptColHeading">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td className="titleline">Time</td>
              <td className="nameTitle">Patient Name</td>
              <td className="genderTitle">Gender</td>
              <td className="apptTimeTitle">Appt. Time</td>
              <td className="mobileTitle">Mobile Number</td>
              {localStorage.getItem("payment_visibility") === "true" ? (
                <td className="mobileTitle">
                  Payment(&#x20b9;)
                  {props.selectedCategory === 1 ? <div>Rec./Doc.</div> : null}
                </td>
              ) : (
                <td
                  style={{
                    width: props.selectedCategory === 1 ? "15%" : "15%",
                  }}
                ></td>
              )}
              {localStorage.getItem("payment_visibility") === "true" ? (
                <td className={props.selectedCategory === 1 ? "emptycol" : ""}>
                  {props.selectedCategory === 1
                    ? "Edit Payment"
                    : props.selectedCategory === 2
                    ? "Updated Time"
                    : null}
                </td>
              ) : null}
              {!localStorage.getItem("payment_visibility") ||
              localStorage.getItem("payment_visibility") === "false" ? (
                <td className="emptycol">
                  {props.selectedCategory === 1
                    ? "Updated Time"
                    : props.selectedCategory === 2
                    ? "Updated Time"
                    : null}
                </td>
              ) : null}
              {/* {(!localStorage.getItem('payment_visibility') || localStorage.getItem('payment_visibility')==='false') ? <td className="emptycol">Updated Time</td> : null} */}
              {/* <td>Amount</td>
                        <td>Amount</td> */}
              {localStorage.getItem("scan_visibility") === "true" &&
              props.selectedCategory === 1 ? (
                <td>Rx</td>
              ) : (
                <td></td>
              )}
            </tr>
          </thead>
        </table>
        {/* <div className="titleline">Time</div>
                <div className = "patientApptTitle">
                    <div className="nameTitle">Patient Name</div>
                    <div className="genderTitle">Gender/Age</div>
                    <div className="apptTimeTitle">Appt. Time</div>
                    <div className="mobileTitle">Mobile Number</div>
                    <div className="mobileTitle">Amount</div>
                    {(props.selectedCategory==1) ? (localStorage.getItem("payment_visibility")==="true") ? <div className="editpay">Edit Payment</div> :<div className="updatedtmTitle">Checkout Time</div> : null}
                    {(props.selectedCategory==2) ? <div className="updatedtmTitle">Cancelled at</div> : null}
                </div> */}
      </div>

      <div className="padSide-10">
        <div className="dayAppointments">
          {recentAppts.length > 5 && props.selectedCategory === 0 ? (
            <div className="recentAppts">
              <div className="recentApptBox">
                <div onClick={toggle} className="recentApptHeading">
                  Last 3 Updated{" "}
                  <span>
                    <img src={collapse ? upIcon : downIcon} alt="" />
                  </span>
                </div>
                <Collapse
                  isOpen={collapse}
                  onEntering={onEntering}
                  onEntered={onEntered}
                  onExiting={onExiting}
                  onExited={onExited}
                >
                  <Card>
                    <CardBody>
                      <div className="recentApptsContainer">
                        <div className="spaceConsumer"></div>
                        <div className="recentApptsList">
                          {recentApptTemplate}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              {/* <span className="recentApptHeading">Last 3 Added</span>
                        <div className="recentApptsContainer">
                            <div className="spaceConsumer"></div>
                            <div className='recentApptsList'>{recentApptTemplate}</div>
                        </div> */}
            </div>
          ) : null}
          {apptView}
          {/* <InfiniteScroll 
                    loadMore={()=>{
                        if(!props.isLoading){
                        props.loadMore()
                    }}}
                    hasMore={props.isMoreDataExist} 
                    loader={<div className="dayViewLoader"><img src={Spinner} /></div>}
                    useWindow={false}
                    initialLoad ={false}
                >
                    {apptView}
                </InfiniteScroll> */}
        </div>
      </div>
    </div>
  );
}

export default DayView;
