import React from 'react';
import CalenderDate from './CalenderDate/CalenderDate';

function MonthCalender() {
    const calender= Array(35).fill(null).map((item, index) => (
      <CalenderDate key={index} date={index} />
    ));
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayColumn = weekDays.map((day) => (
      <div className="dayColumn" key={day}>
        {day}
      </div>
    ))
    return (
      <div className="monthCalender">
        {dayColumn}
        { calender }
      </div>
    );
  }

  export default MonthCalender;