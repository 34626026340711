import React from 'react';
function DateCard(props) {
    return (
        <div className={(props.isSelected) ? 'selectDateCard activeDate' : 'selectDateCard'} >
            <div className={(props.isSelected) ? "selectDate colorWhite":"selectDate"}>
                {props.date.format('DD MMMM')}
            </div>
            <div className={(props.isSelected) ? "selectDay colorWhite":"selectDay"}>
                {props.date.format('dddd')}
            </div>
        </div>
    )
}

export default DateCard