import React from "react";
import docIcon from "../../../assets/images/doctor.svg";
import dateTimeIcon from "../../../assets/images/date_time.png";
import choosePatIcon from "../../../assets/images/choose_patient.png";
import moment from "moment";
import "./AppointmentSummary.css";

function AppointmentSummary(props) {
  console.log(props.isValid);

  let doctorName = null;
  if (props.doctor) {
    if (props.isHospital) {
      doctorName = props.doctor.doctor_fullnm;
    } else {
      doctorName = props.doctor.docName;
    }
  }

  return (
    <div className="appointmentSummary">
      <div className="summaryTitle">Appointment Summary</div>

      <div className="apptSummaryCard">
        <div className="apptSummaryInnercards">
          <div
            className={
              "chooseDoctorCard innerCard " +
              (props.view === 0 ? "activeCat" : "")
            }
          >
            <img src={docIcon} alt="choose doctor" />
            <div className="chooseData activeCard">
              <div
                className="chooseText"
                style={{ color: props.view === 0 ? "#2886af" : "initial" }}
              >
                {doctorName ? "Doctor" : "Choose"}
              </div>
              <div
                className="chooseContent"
                style={{ color: props.view === 0 ? "#2886af" : "initial" }}
              >
                {doctorName ? doctorName : "Doctor"}
              </div>
            </div>
          </div>
          <div
            className={
              "chooseDateTimeCard innerCard " +
              (props.view === 1 ? "activeCat" : "")
            }
          >
            <img src={dateTimeIcon} alt="choose date" />
            <div className="chooseData">
              <div
                className="chooseText"
                style={{ color: props.view === 1 ? "#2886af" : "initial" }}
              >
                {props.selectedSlot
                  ? new moment(props.selectedSlot).format("DD MMM, YYYY")
                  : "Choose"}
              </div>
              <div
                className="chooseContent"
                style={{ color: props.view === 1 ? "#2886af" : "initial" }}
              >
                {props.selectedSlot
                  ? new moment(props.selectedSlot).format("hh:mm A")
                  : "Date and Time"}
              </div>
            </div>
          </div>
          <div
            className={
              "choosePatientCard innerCard " +
              (props.view === 2 || props.view === 3 ? "activeCat" : "")
            }
          >
            <img src={choosePatIcon} alt="choose patient" />
            <div className="chooseData">
              <div
                className="chooseText"
                style={{
                  color:
                    props.view === 2 || props.view === 3
                      ? "#2886af"
                      : "initial",
                }}
              >
                Choose
              </div>
              <div
                className="chooseContent"
                style={{
                  color:
                    props.view === 2 || props.view === 3
                      ? "#2886af"
                      : "initial",
                }}
              >
                Patient
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            className="makeAppt-Btn"
            onClick={props.makeAppointment}
            disabled={
              props.view != 3 ||
              props.apptInProgress ||
              !props.selectedPatient ||
              (props.selectedPatient &&
                props.selectedPatient.patient_fullnm.trim().length < 3) ||
              !props.selectedSlot
            }
          >
            {props.apptType !== "edit"
              ? "Make Appointment"
              : "Update Appointment"}
          </button>
        </div>
      </div>

      {/* <AppointmentSummary /> */}
    </div>
  );
}

export default AppointmentSummary;
