import React, { Component } from "react";
import "./LoginPage.css";
import LoginForm from "../../components/LoginForm/LoginForm";
import axios from "../../axios-order";
import { connect } from "react-redux";
import Spinner from "../../assets/images/spinner.gif";

class LoginPage extends Component {
  state = {
    isFormValid: false,
    invalidMsg: "",
    username: "",
    password: "",
    addresses: null,
    isLoader: false,
  };

  componentDidMount() {
    //Redirect to Dashboard Page when User is already authenticated
    this.nameChangeHandler(this.state.username);
    this.passwordChangeHandler(this.state.password);
    // console.log('Login Component Has been Updated.');
    // console.log('IS User Authenticated : ', this.props.user.isAuthenticated);
  }

  componentDidUpdate() {
    if (this.props.user.isAuthenticated) {
      this.props.history.push("/reception/dashboard/dayview");
    }
  }

  checkformvalidation(username, password) {
    return username && username.length > 0 && password && password.length > 0;
  }

  nameChangeHandler(value) {
    this.setState({
      username: value,
      isFormValid: this.checkformvalidation(value, this.state.password),
    });
  }

  passwordChangeHandler(value) {
    this.setState({
      password: value,
      isFormValid: this.checkformvalidation(this.state.username, value),
    });
  }

  selectAddressHandler(addId) {
    localStorage.setItem("addId", addId);
    this.props.isAuthenticated(true);
  }

  submitHandler(e) {
    e.preventDefault();
    this.setState({ isFormValid: false, isLoader: true }); //disable login button

    const dataObj = {
      sign_in: {
        username: this.state.username,
        password: this.state.password,
      },
    };
    axios.post("/masterSignIn_Reception_web", dataObj).then(
      (response) => {
        console.log("LOGIN RESPONSE", response);
        // debugger;
        let selectedAddress = null;
        if (response.data.sign_in.address.length > 1) {
          //For multiple addresses with individual doctor
          this.setState({ address: response.data.sign_in.address });
        } else {
          //For single address
          selectedAddress = response.data.sign_in.address[0];
        }
        //Perpare data to be stored in using reducer
        if (response.data) {
          const userData = {
            isHospital: response.data.sign_in.reception_app_web_credential,
            docName: response.data.sign_in.doctor_fullnm,
            docId: response.data.sign_in.doctor_id,
            docImage: response.data.sign_in.doctor_image,
            specialization: response.data.sign_in.specialization,
            doctors: response.data.sign_in.doctors,
          };
          // debugger
          const isPrintPresc =
            userData.doctors.filter((doc) => {
              console.log(doc.print_prescription);
              return doc.print_prescription;
            }).length > 0;
          const isQuickAppt =
            userData.doctors.filter((doc) => {
              console.log(doc.quick_appointment);
              return doc.quick_appointment;
            }).length > 0;
          this.props.userData(userData);
          localStorage.setItem("loginId", userData.docId);
          localStorage.setItem("userName", response.data.sign_in.doctor_fullnm);
          localStorage.setItem(
            "userSpecialization",
            response.data.sign_in.specialization
          );
          localStorage.setItem(
            "is_master",
            userData.doctors.length > 1 ? "true" : "false"
          );
          localStorage.setItem(
            "payment_visibility",
            response.data.sign_in.quick_amount_reception_web
          );
          localStorage.setItem("print_prescription", isPrintPresc);
          localStorage.setItem("quick_appt", isQuickAppt);
          // localStorage.setItem('payment_visibility', response.data.sign_in.);
          localStorage.setItem(
            "scan_visibility",
            response.data.sign_in.reception_standalone
          );
          localStorage.setItem("is_quick_mode", true);

          if (selectedAddress) {
            this.selectAddressHandler(selectedAddress.address_id);
          }
        } else {
          //Error instructions to handle empty response..
        }
      },
      (error) => {
        if (
          error.response &&
          (error.response.status === 403 || error.response.status === 401)
        ) {
          this.setState({ invalidMsg: "! Invalid Username or Password." });
        } else {
          alert("Unknown Error Occoured");
        }
        this.setState({ isFormValid: true, isLoader: false });
      }
    );
  }

  render() {
    return (
      <div className="loginScreenContainer">
        <div
          className={`shiftLoader ${
            this.state.isLoader ? "blurBackground" : ""
          }`}
        >
          <LoginForm
            changeName={(value) => this.nameChangeHandler(value)}
            changePassword={(value) => this.passwordChangeHandler(value)}
            isformvalid={this.state.isFormValid}
            submit={(e) => this.submitHandler(e)}
            msg={this.state.invalidMsg}
            addresses={this.state.addresses}
            selectAddress={(addId) => this.selectAddressHandler(addId)}
          />
        </div>
        {this.state.isLoader ? (
          <div className="spinnerContainer">
            <img src={Spinner} alt="" />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    userData: (userData) =>
      dispatch({ type: "STORE_DOCTORS_DATA", userData: userData }),
    isAuthenticated: (authenticate) =>
      dispatch({ type: "AUTHENTICATE_USER", authenticated: authenticate }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
