import React, { Component } from "react";
import { connect } from "react-redux";
import paymentStyle from "./PatientProfile.css";
import { Col } from "react-bootstrap";
import patIcon from "../../assets/images/patientImage.png";
import Invoice from "../../components/invoice/invoice";
import { Link } from "react-router-dom";
import editIconImage from "../../assets/images/icons/edit-white.svg";
import printerIconImage from "../../assets/images/icons/printer.svg";

const invoices = [
  {
    invoiceNo: "#inv33655214756584",
    invoiceDateTime: "October 13, 2019 23:15:30 GMT+07:00",
    services: [
      {
        serviceName: "Regular Consultation",
        cost: 1000,
        unit: 2,
        total: 2000,
      },
      {
        serviceName: "Services Name",
        cost: 500,
        unit: 2,
        total: 1000,
      },
      {
        serviceName: "Services Name",
        cost: 500,
        unit: 2,
        total: 1000,
      },
    ],
    billedAmount: 4000,
    discount: 500,
    totalAmount: 3500,
    paidAmount: 3000,
    balance: 500,
    advance: 0,
  },

  {
    invoiceNo: "#inv33655214756585",
    invoiceDateTime: "October 14, 2019 20:05:14 GMT+07:00",
    services: [
      {
        serviceName: "Regular Consultation",
        cost: 1000,
        unit: 2,
        total: 2000,
      },
      {
        serviceName: "Services Name",
        cost: 1000,
        unit: 2,
        total: 2000,
      },
      {
        serviceName: "Services Name",
        cost: 500,
        unit: 2,
        total: 1000,
      },
    ],
    billedAmount: 5000,
    discount: 500,
    totalAmount: 4500,
    paidAmount: 5000,
    balance: 0,
    advance: 500,
  },
];

/**
 * This method is rendering left service item in payment card
 * @param {*} param0
 */

function ServiceItem({ medicalService }) {
  if (!medicalService || (medicalService && medicalService.amount === 0)) {
    return (
      <p className="serviceItem">
        <span className="serviceName">No services</span>
      </p>
    );
  }
  return (
    <>
      <p className="serviceItem">
        <span className="rupees">Rs</span>
        <span className="payment">{medicalService.amount}</span>
        <span className="hyphen">-</span>
        <span className="serviceName">{medicalService.name}</span>
      </p>
    </>
  );
}

function ShowServiceItem({ services }) {
  console.log(services);
  let serviceItem = services.map((service, index) => {
    console.log(service);
    return (
      <ServiceItem
        key={index}
        serviceName={service.serviceName}
        serviceCost={service.cost}
        unit={service.unit}
        total={service.total}
      />
    );
  });
  return serviceItem;
}

class DateMonth extends Component {
  render() {
    return (
      <div className="invoiceDateContainer">
        <div className="invoiceMonthDate">Oct, 2019</div>
      </div>
    );
  }
}

class Appointments extends Component {
  render() {
    return <div>Appointment</div>;
  }
}

function TabDetail({ tabName, invoices }) {
  console.log(tabName, invoices);
  let tabDetail =
    tabName === "APPOINTMENTS" ? (
      <Appointments />
    ) : (
      <PatientInvoices invoices={invoices} />
    );
  return tabDetail;
}

class TabDetails extends Component {
  render() {
    let { tabName } = this.props;
    let { invoices } = this.props;
    console.log(tabName, invoices);
    return (
      <div className="tabDetails">
        <TabDetail tabName={tabName} invoices={invoices} />
      </div>
    );
  }
}

class InvoiceBody extends Component {
  render() {
    let invoice = this.props.invoice;
    let { services } = this.props.invoice;
    console.log(services);
    console.log(invoice);
    console.log(this.props.invoice);

    return (
      <div className="invoiceDetailsContainer">
        <div className="invoiceTable">
          <table>
            <thead>
              <tr className="tableHeading">
                <th className="itemHeading">Item</th>
                <th className="costHeading">Cost</th>
                <th className="unitHeading">Unit</th>
                <th className="totalHeading">Total</th>
              </tr>
            </thead>
            <tbody>
              <ShowServiceItem services={services} />
            </tbody>
          </table>
        </div>
        <div className="invoicePaymentDetails">
          <div className="invoicePaymentDetailsContainer">
            <div className="billedPayment">
              <div className="label">Billed Amount</div>
              <div className="billedAmount amount">{invoice.billedAmount}</div>
            </div>
            <div className="discount">
              <div className="label discountLabel">Discount</div>
              <div className="discountAmount amount">-{invoice.discount}</div>
            </div>
            <div className="total">
              <div className="label">Total Amount</div>
              <div className="totalAmount amount">{invoice.totalAmount}</div>
            </div>
            <div className="paid">
              <div className="label">Paid Amount</div>
              <div className="paidAmount amount">{invoice.paidAmount}</div>
            </div>
            <div className="balance">
              <div className="label balanceLabel">
                {invoice.advance ? "Advance" : "Balance"}
              </div>

              <div
                className={
                  invoice.advance
                    ? "balanceAmount amount green"
                    : "balanceAmount amount red"
                }
              >
                {invoice.advance ? invoice.advance : invoice.balance}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class InvoicesHeader extends Component {
  render() {
    return (
      <div>
        <MainHeader />
        <DateMonth />
      </div>
    );
  }
}

class InvoiceHeader extends Component {
  render() {
    let { invoiceNo } = this.props;
    let { invoice } = this.props;
    console.log(invoice);
    // let {invoiceDateTime} = this.props;
    // let {invoiceBill} = this.props;
    let advance = this.props.advance;
    let balance = this.props.balance;
    console.log("Advance or balance is ", advance, balance);
    return (
      <div className="invoiceHeaderContainer">
        <div className="invoiceHeaderLeft">
          <div className="invoiceNumber">{invoiceNo}</div>
          <div className="invoiceDateTime">
            <div className="invoiceDate">13 Oct, 2019</div>
            <div className="invoiceTime">12:30 PM</div>
          </div>
        </div>
        <div className="invoiceHeaderRight">
          <div
            className="invoiceCollectRefund"
            onClick={() => this.props.setModalShow(true, invoice)}
          >
            <div className="invoiceCollectRefundLabel">
              {advance ? "Refund" : "Collect"}
            </div>
            <div className="invoiceCollectRefundPayment">
              <div className="currencySymbol">&#8377;</div>
              <div className="invoiceCollectRefundAmount">500</div>
            </div>
          </div>
          <div className="editInvoiceIcon">
            <img src={editIconImage} className="editIconImage" alt="" />
          </div>
          <div className="printInvoiceIcon">
            <img src={printerIconImage} className="printerIconImage" alt="" />
          </div>
        </div>
      </div>
    );
  }
}

class MainHeader extends Component {
  render() {
    return (
      <div className="invoicesHeader">
        <div className="invoicesLeft">Invoices</div>
        <div className="invoicesRight">
          <div className="collectRefundPayment">
            <span className="collectRefundLabel">Collect</span>
            <span className="redColor">&#8377;</span>
            <span className="collectRefundAmount redColor">3000</span>
          </div>

          <Link to="/NewInvoice" className="addInvoiceBtn">
            Add Invoice
          </Link>
        </div>
      </div>
    );
  }
}

class PatientInvoice extends Component {
  render() {
    let { invoice } = this.props;
    console.log(invoice);
    return (
      <div className="patientInvoice">
        <InvoiceHeader
          invoice={invoice}
          invoiceNo={invoice.invoiceNo}
          invoiceDateTime={invoice.invoiceDateTime}
          advance={invoice.advance}
          balance={invoice.balance}
          setModalShow={this.props.setModalShow}
        />
        <InvoiceBody invoice={invoice} />
      </div>
    );
  }
}

class PatientInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // setModalShow(isShow){
  //     console.log(isShow);
  // }

  render() {
    let { invoices } = this.props;
    console.log(invoices);
    let patInvoice = invoices.map((invoice) => {
      return (
        <PatientInvoice
          key={invoice.invoiceNo}
          invoice={invoice}
          setModalShow={this.props.setModalShow}
        />
      );
    });
    return (
      <>
        <div className="patientInvoicesContainer">
          {/* <InvoicesHeader 
                    /> */}
          <div className="patientInvoicesList">
            {patInvoice}
            {/* PatientInvoice */}
          </div>
        </div>
      </>
    );
  }
}

function Tab({ tab, index, handleInvoiceTab }) {
  return (
    <div
      key={index}
      className={tab.isTabActive === true ? "invoiceTab active" : "invoiceTab"}
      onClick={() => handleInvoiceTab(index, tab)}
    >
      {tab.tabName}
    </div>
  );
}

function ShowTab({ tabs, handleInvoiceTab, handleChangeTabName }) {
  console.log(tabs);
  let tab = tabs.map((tab, index) => {
    return (
      <Tab
        key={index}
        index={index}
        tab={tab}
        handleInvoiceTab={handleInvoiceTab}
        handleChangeTabName={handleChangeTabName}
      />
    );
  });

  return <div className="invoicesTabs">{/* {tab} */}</div>;
}

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          tabName: "APPOINTMENTS",
          isTabActive: false,
        },
        {
          tabName: "BILLING",
          isTabActive: true,
        },
      ],
    };
  }

  /**
   * Change Tab active state on click on tab
   * @param {*} tabs
   * @param {*} index
   * @param {*} isTabActive
   */
  changeActiveTab(tabs, index) {
    console.log(tabs, index);
    tabs.map((tabObj, tabIndex) => {
      if (index === tabIndex) {
        tabObj.isTabActive = true;
        tabs[tabIndex] = tabObj;
      } else {
        tabObj.isTabActive = false;
        tabs[tabIndex] = tabObj;
      }
    });
    return tabs;
  }

  /**
   * Handle on click on tab
   * @param {*} index
   * @param {*} tab
   */
  handleInvoiceTab(index, tab, handleChangeTabName) {
    console.log(index, tab);
    let tabs = this.state.tabs;
    tabs = this.changeActiveTab(tabs, index);
    handleChangeTabName(tab.tabName);
    console.log(tabs);
    this.setState({
      tabs: tabs,
    });
  }

  render() {
    let tabs = this.state.tabs;
    console.log(tabs);
    return (
      <div></div>
      // <ShowTab
      //     tabs={tabs}
      //     handleInvoiceTab={(index,tab)=>this.handleInvoiceTab(index, tab, this.props.handleChangeTabName)}
      // />
    );
  }
}

function AppointmentHistory() {
  const apptDetailCard = new Array(8).fill(null).map((item) => {
    return (
      <div class="apptCard">
        <div class="apptTimings">
          <div class="apptTiming">
            <div class="apptTime lightFontColor">11:00 am</div>
            <div class="apptDate">
              3rd August
              <div class="apptYear lightFontColor">2018</div>
            </div>
          </div>
          <div class="circle"></div>
          <div class="line"></div>
        </div>
        <div className="apptDetailCard">
          <div className="apptDetails">
            <div className="apptServices">
              <div className="serviceHeading apptCardHeading">
                Services <div className="servicesCount">+2</div>
              </div>
              <div className="servicesNames">
                <div className="serviceName">Physician assistants</div>
                <div className="serviceName">Nurse Practitioners</div>
              </div>
            </div>
            <div class="apptPayment">
              <div className="paymentHeading apptCardHeading">Payment</div>
              <div className="payment">
                Rs. <span class="paymentNumber">500</span>
              </div>
            </div>
            <div class="apptStatus">
              <div className="statusHeading apptCardHeading">Status</div>
              <div className="pp-status">Confirmed</div>
            </div>
            <div className="apptButtons">
              <span className="apptButton">Reschedule</span>
              <span className="apptButton cancelButton">Cancel</span>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return <>{apptDetailCard} </>;
}

function Billing() {
  const invoices = new Array(10).fill(null).map((item) => {
    return <Invoice></Invoice>;
  });
  return invoices;
}

class PatientProfile extends Component {
  state = {
    patientDetails: null,
    appointmentHistory: null,
    invoiceList: null,
    selectedTab: 0,
  };

  componentDidMount() {
    //When component first mount after creation
  }

  switchTabHandler(type) {
    this.setState({ selectedTab: type });
  }

  render() {
    return (
      <div className="patientProfile">
        <div className="pprLeft">
          <div>
            <div className="profileHead">
              <div>
                <img src={patIcon} />
              </div>
              <p className="namePat">Ritu Tiwari (25/F)</p>
              <p className="numPat">+91- 8860144308</p>
            </div>
            <div>
              <div>
                <h4>Account Details</h4>
                <table>
                  <tr>
                    <td className="key">Visit</td>
                    <td className="value">7th</td>
                  </tr>
                  <tr>
                    <td className="key">Last Visit</td>
                    <td className="value">8th April, 2018</td>
                  </tr>
                  <tr>
                    <td className="key">Amount Due</td>
                    <td className="value">Rs 500</td>
                  </tr>
                  <tr>
                    <td className="key">Average Time</td>
                    <td className="value">15 min</td>
                  </tr>
                </table>
              </div>
              <div>
                <h4>Vital Details</h4>
                <table>
                  <tr>
                    <td className="key">Visit</td>
                    <td className="value">7th</td>
                  </tr>
                  <tr>
                    <td className="key">Last Visit</td>
                    <td className="value">8th April, 2018</td>
                  </tr>
                  <tr>
                    <td className="key">Amount Due</td>
                    <td className="value">Rs 500</td>
                  </tr>
                  <tr>
                    <td className="key">Average Time</td>
                    <td className="value">15 min</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="pprRight">
          <div className="pprTabs">
            <div onClick={() => this.switchTabHandler(0)}>Appointments</div>
            <div onClick={() => this.switchTabHandler(1)}>Billing</div>
          </div>
          {this.state.selectedTab !== 0 ? <MainHeader /> : null}
          <div className="pprInvoiceContainer">
            {this.state.selectedTab === 0 ? (
              <AppointmentHistory></AppointmentHistory>
            ) : (
              <>
                <Tabs
                  handleChangeTabName={(tabName) =>
                    this.handleChangeTabName(tabName)
                  }
                />
                <TabDetails tabName={this.state.tabName} invoices={invoices} />
              </>
            )}
          </div>
        </div>
        {/* <div className="ppLeft"> */}
        {/* <div class="patientProfileSideBar">
                        <div class="patientProfileCard">
                            <div class="patientProfileContainer">
                                <div class="patientPhoto">
                                    <img src={patIcon} alt="" />
                                </div>
                                <div class="patientDetails">
                                    <div class="basicDetails">
                                        <div class="pp-patientName">
                                            Ritu Tiwari
                                        </div>
                                        <div class="ageGender">
                                            (<span class="patientAge">25</span>/<span class="patientGender">F</span>)
                                        </div>
                                    </div>
                                    <div class="mobileNumber">
                                        <span class="phoneCode">+91</span>- <span class="pp-number">8860144308</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="patientOtherDetails">
                            <div class="patientAccountDetails">
                                <div class="detailsMainHeading">
                                    Account Details
                                </div>
                                <div class="accountDetails">
                                    <div class="timeVisited detailsRow">
                                        <span class="detailsHeading">Visit</span>
                                        <span class="detailsValue">7th</span>
                                    </div>
                                    <div class="lastVisit detailsRow">
                                        <span class="detailsHeading">Last Visit</span>
                                        <span class="detailsValue">8th Apr, 2018</span>
                                    </div>
                                    <div class="amountDue detailsRow">
                                        <span class="detailsHeading">Amount Due</span>
                                        <span class="detailsValue">Rs. <span class="dueAmountNumber">500</span> </span>
                                    </div>
                                    <div class="averageTime detailsRow">class="detailsValue">15min</span>
                                </div>
                                        {/* <span class="detailsHeading">Average Time</span>
                                        <span class="detailsValue">15min</span>
                                    </div> */}
        {/* </div> */}
        {/* </div>
                            <div class="patientVitalDetails">
                                <div class="detailsMainHeading">
                                    Vital Details
                                </div>
                                <div class="vitalDetails">
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Height</span>
                                        <span class="detailsValue">186 <span class="unit">cm</span></span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Weight</span>
                                        <span class="detailsValue">86<span class="unit">kg</span> </span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Blood Group</span>
                                        <span class="detailsValue">B+</span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">Temperature</span>
                                        <span class="detailsValue">103</span>
                                    </div>
                                    <div class="detailsRow">
                                        <span class="detailsHeading">BP</span>
                                        <span class="detailsValue">135/90</span>
                                    </div>
                                </div>       
                            </div>
                        </div> */}
        {/* </div> */} */}
        {/* </div> */}
        {/* <div className="ppRight">
                    <div>
                    <div className="invoicesTabs">
                        <div className={'invoiceTab ' + ((this.state.selectedTab===0) ? 'active' : '')} onClick={()=>this.switchTabHandler(0)}>
                            APPOINTMENTS
                        </div>
                        <div className={'invoiceTab ' + ((this.state.selectedTab===1) ? 'active' : '')} onClick={()=>this.switchTabHandler(1)}>
                            BILLING
                        </div>
                    </div>
                    </div>
                    <div>
                        {(this.state.selectedTab===0) ? <AppointmentHistory></AppointmentHistory> : <Billing></Billing>}
                    </div>
                </div> */}
        <div></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
