import React from 'react';

function CalenderDate(props) {
    return (
      <div className="box">
        <div className="number">
            {props.date+1}
        </div>
        <div className="box-items">
            <div>
              <div className="bullets blue"></div> 
              <div className="status">Patient Seen</div>
            </div> 
            <span className="count">120</span>
        </div>
        <div className="box-items">
            <div>
              <div className="bullets grey"></div> 
              <div className="status">Cancled</div>  
            </div>             
            <span className="count">50</span>
        </div>
        <div className="box-items">
          <div>
            <div className="bullets backgroundRed"></div> 
            <div className="status">No Show</div>
          </div> 
            <span className="count">6</span>
        </div>
      </div>
    );
  }

export default CalenderDate;