import React from "react";

const Progress = () => {
  return (
    <div className="deleteMessageContainer">
      <h1>Please wait...</h1>
    </div>
  );
};
export default Progress;
